@import "./bootstrap.min.css";

/*------------------------------------------------------------------
[Main Stylesheet]

Project:    ODDO
Version:    1.1.0
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Table of contents]
    *. Login Layout 1 / .login-1
    *. Login Layout 2 / .login-2
    *. Login Layout 3 / .login-3
    *. Login Layout 4 / .login-4
    *. Login Layout 5 / .login-28
    *. Login Layout 6 / .login-6
    *. Login Layout 7 / .login-26
    *. Login Layout 8 / .login-30
    *. Login Layout 9 / .login-9
    *. Login Layout 10 / .login-10
    *. Login Layout 11 / .login-11
    *. Login Layout 12 / .login-12
    *. Login Layout 13 / .login-43
    *. Login Layout 14 / .login-14
    *. Login Layout 15 / .login-15
    *. Login Layout 16 / .login-16
    *. Login Layout 17 / .login-17
    *. Login Layout 18 / .login-18
    *. Login Layout 19 / .login-19
    *. Login Layout 20 / .login-33
    *. Login Layout 21 / .login-21
    *. Login Layout 22 / .login-22
    *. Login Layout 23 / .login-32
    *. Login Layout 24 / .login-24
    *. Login Layout 25 / .login-25
    *. Login Layout 26 / .login-31
    *. Login Layout 27 / .login-27
    *. Login Layout 28 / .login-35
    *. Login Layout 29 / .login-29
    *. Login Layout 30 / .login-23
    *. Login Layout 31 / .login-5
    *. Login Layout 32 / .login-7
    *. Login Layout 33 / .login-20
    *. Login Layout 34 / .login-34
    *. Login Layout 35 / .login-8

    *. Login inner form / .login-inner-form
    *. Login inner form details / .details
-------------------------------------------------------------------*/
/** GLOBAL CLASSES **/

body {
    font-family: 'Open Sans', sans-serif;
    color: #535353;
}

.img-fluid {
    max-width: 100% !important;
    height: auto;
}

.form-control:focus {
    box-shadow: none;
}

.login-box .alert{
    padding: 15.5px 20px;
    font-size: 13px;
    border: none !important;
    border-radius: 3px;
    margin-bottom: 25px;
}

.login-box .alert-dismissible .btn-close{
    padding: 18.5px 19px;
    background-size: 8px;
}

.login-box .alert-dismissible .btn-close:focus,
.login-box .alert-dismissible .btn-close:active {
    box-shadow: none;
}

.login-box .password-indicator {
    position: absolute;
    right: 15px;
    top: 17px;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 500ms;
    font-size: 17px;
    color: #999;
}

.login-box .password-wrapper:hover .password-indicator{
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 500ms;
    font-size: 17px;
    color: #555;
}

.login-box .login-popover{
    font-size: 14px;
    color: #777;
    cursor: help;
}

.login-box .login-popover-abs{
    position: absolute;
    right: 15px;
    top: 15px;
}

.popover {
    border: 1px solid #ebeef5 !important;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.popover-header {
    box-shadow: none;
    border: 0px;
    background: #fff;
    color: #555;
    font-size: 15px;
    font-family: 'Jost', sans-serif;
    padding-bottom: 5px;
}

.popover-body {
    padding: 5px 10px 10px 16px;
    color: #777;
    font-size: 13px;
}

.login-box .form-group label.error{
    font-size: 13px;
    text-align: left;
    color: #535353;
    width: 100%;
}

/** Login 1 start **/
.login-1 .form-section {
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
    background: #fff2f2;
}

.login-1 a {
    text-decoration: none;
    color: #535353;
}

.login-1 .form-section p p{
    color: #535353;
}

.login-1 .form-inner {
    max-width: 500px;
    width: 100%;
}

.login-1 .bg-img {
    /* background: url(../../assets/img/img-1.jpg) top left repeat; */
    background-size: cover;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-1 .bg-img:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(36 10 99 / 74%);
}

.login-1 .form-section .extra-login {
    margin-bottom: 25px;
    position: relative;
}

.login-1 .form-section .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #d8dcdc;
    content: "";
}

.login-1 .form-section .extra-login > span {
    padding: 1px 20px;
    position: relative;
    font-size: 15px;
    color: #535353;
    background: #fff2f2;
}

.login-1 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    position: absolute;
    border-radius: 2px;
    border: none;
    background-color: #fff;
    margin-left: -22px;
}

.login-1 .form-check-input:focus {
    border-color: snow;
    outline: 0;
    box-shadow: none;
}

.login-1 .form-check-input:checked {
    background-color: #f5c025!important;
}

.login-1 .form-section p {
    margin-bottom: 0;
    font-size: 16px;
}

.login-1 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-1 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-1 .form-section .thembo {
    margin-left: 4px;
}

.login-1 .form-section h3 {
    margin: 0 0 25px;
    font-size: 25px;
    font-weight: 400;
}

.login-1 .form-section .form-group {
    margin-bottom: 25px;
}

.login-1 .form-section .form-control {
    padding: 11px 20px 9px;
    font-size: 16px;
    outline: none;
    height: 50px;
    color: #535353;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid transparent;
    background: #fff;
}

.login-1 .form-section .form-check {
    margin-bottom: 0;
}

.login-1 .form-section .form-check-label {
    padding-left: 5px;
    font-size: 16px;
    color: #535353;
}

.login-1 .form-section a.forgot-password {
    font-size: 16px;
}

.login-1 .form-section a.forgot-password:hover{
    color: #535353;
}

.login-1 .logo img {
    margin-bottom: 15px;
    height: 25px;
}

.login-1 .btn-theme {
    position: relative;
    display: inline-block;
    border: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    width: 100%;
}

.login-1 .btn-theme:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    border-radius: 3px;
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

.login-1 .btn-theme:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.login-1 .btn-lg{
    padding: 0 50px;
    line-height: 50px;
}

.login-1 .btn{
    box-shadow: none!important;
}

.login-1 .btn-primary{
    background: #f5c025;
}

.login-1 .btn-primary:after {
    background: #e3af18;
}

.login-1 .social-list{
    display: inline-flex;
    margin-bottom: 27px;
}

.login-1 .social-list .icon {
    position: relative;
    background: #f3f3f3;
    border-radius: 3px;
    margin: 0 4px 3px 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #ffffff;
}

.login-1 .social-list .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-1 .social-list .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-1 .social-list .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.login-1 .social-list .icon:hover span,
.login-1 .social-list .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.login-1 .social-list .facebook{
    background: #3b5999;
}

.login-1 .social-list .facebook:hover .tooltip,
.login-1 .social-list .facebook:hover .tooltip::before {
    background: #3b5999;
}

.login-1 .social-list .twitter{
    background: #46c1f6;
}

.login-1 .social-list .twitter:hover .tooltip,
.login-1 .social-list .twitter:hover .tooltip::before {
    background: #46c1f6;
}

.login-1 .social-list .instagram{
    background: #db4437;
}

.login-1 .social-list .instagram:hover .tooltip,
.login-1 .social-list .instagram:hover .tooltip::before {
    background: #db4437;
}

.login-1 .social-list .github{
    background: #2392e0;
}

.login-1 .social-list .github:hover .tooltip,
.login-1 .social-list .github:hover .tooltip::before {
    background: #2392e0;
}

.login-1 .lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -1;
}

.login-1 .line {
    position: absolute;
    width: 5px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.login-1 .line::after {
    content: '';
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    animation: drop 7s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    z-index: -888;
}

.login-1 .line:nth-child(1) {
    margin-left: -40%;
}

.login-1 .line:nth-child(1)::after {
    animation-delay: 1s;
}

.login-1 .line:nth-child(3) {
    margin-left: 40%;
}

.login-1 .line:nth-child(3)::after {
    animation-delay: 2s;
}

.login-1 .line:nth-child(4) {
    margin-left: -20%;
}

.login-1 .line:nth-child(4)::after {
    animation-delay: 3s;
}

.login-1 .line:nth-child(5) {
    margin-left: 20%;
}

.login-1 .line:nth-child(5)::after {
    animation-delay: 4s;
}

@keyframes drop {
    0% {top: -50%; }
    100% {top: 110%; }
}

.login-1 .info{
    max-width: 650px;
}

.login-1  .animated-text h1{
    display: block;
    text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
    /* background: url(../../assets/img/animated-text.png) repeat-y; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: aitf 80s linear infinite;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
}

.login-1 .info h1 {
    font-size: 60px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.login-1 .info p {
    margin-bottom: 0;
    color: #fff;
    opacity: 0.9;
    line-height: 28px;
}

@media (max-width: 992px){
    .login-1 .none-992 {
        display: none !important;
    }
}

/** Login 2 start **/
.login-2 .login-2-inner {
    z-index: 999;
    position: relative;
    min-height: 100vh;
    /* text-align: center; */
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 30px 0;
}

.login-2 .login-2-inner:before {
    content: "";
    width: 35%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* background: url(../../assets/img/img-53.png) top left repeat; */
    background-position: center center;
    background-size: cover;
    border-radius: 0 30px 30px 0;
}


.login-2 .login-box {
    background: #fff;
    border-radius: 20px;
    margin: 0 auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    background: url(../../assets/img/bg.jpg) top left repeat;
    background-size: cover;
    position: relative;
    max-width: 1140px;
}

.login-2 .form-section {
    /* text-align: center; */
    padding: 25px 90px 60px;
    background: #fff;
    border-radius: 20px;
}

.login-2 .form-section p{
    color: #535353;
    font-size: 16px;
}

.login-2 .form-section a {
    text-decoration: none;
    color: #535353;
}

.login-2 .pad-0{
    padding: 0;
}

.login-2 .form-section ul{
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-2 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-2 .logo-1 img {
    margin-bottom: 35px;
    /* height: 60px; */
}
.login-2 .logo-2 img {
    margin-bottom: 20px;
    height: 150px;
}
.login-2 .form-section .thembo{
    margin-left: 4px;
}

.login-2 .form-section h3 {
    text-align: center;
    margin: 0 0 25px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-2 .form-section .form-group {
    margin-bottom: 25px;
}

.login-2 .form-section .form-box {
    float: left;
    width: 100%;
    text-align: left;
    position: relative;
}

.login-2 .form-section .form-control {
    padding: 10px 25px;
    font-size: 16px;
    outline: none;
    color: #535353;
    border-radius: 5px;
    border: 1px solid #cfc8c8;
    background: #f3f3f3;
    height: 50px;
}

.login-2 .form-section button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-2 .form-section .terms{
    margin-left: 3px;
}

.login-2 .btn-section {
    margin-top: 15px;
    display: inline-block;
}

.login-2 .btn-section .btn-1 {
    border-radius: 10px 0 0 10px;
    border-right: none !important;
}

.login-2 .btn-section .link-btn {
    font-size: 16px;
    float: left;
    background: #f3f3f3;
    font-weight: 400;
    text-align: center;
    text-decoration: blink;
    line-height: 37px;
    width: 110px;
    color: #0a58a0;
    font-family: 'Jost', sans-serif;
    border: 1px solid #0a58a0;
}

.login-2 .btn-section .link-btn:hover{
    color: #ffffff;
    background: #0a58a0;
}

.login-2 .btn-section .active-bg {
    color: #0dcaf0;
}

.login-2 .btn-section .btn-2 {
    border-radius: 0 10px 10px 0;
}
.login-2 .btn-section .btn-3 {
    border-radius: 0px;
    border-right: none !important;
}

.login-2 .form-section .form-check{
    float: left;
    margin-bottom: 0;
}

.login-2 .form-section .form-check a {
    color: #535353;
}

.login-2 .form-section .form-check-label {
    padding-left: 5px;
    font-size: 16px;
    color: #535353;
}

.login-2 .form-section a.forgot-password {
    line-height: 50px;
}

.login-2 .form-check-input:focus {
    border-color: transparent;
    box-shadow: none;
}

.login-2 .form-check-input:checked {
    background-color: #0dcaf0!important;
}

.login-2 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    position: absolute;
    margin-left: -22px;
}

/*.login-2 .logo{
    display: none;
}*/

.login-2 .social-list a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: inline-block;
    font-size: 19px;
    margin: 2px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
}

.login-2 .login-box:hover .social-list a {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg
    );
}

.login-2 .social-list a:hover{
    color: #fff;
}

.login-2 .btn-theme {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 5px;
    float: left;
}

.login-2 .btn-theme:hover {
    color: #fff;
}

.login-2 .btn-theme:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.login-2 .btn-theme:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    border-radius: 7px;
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
    background: #ea7726;
}

.login-2 .btn-info:after {
    background: #28c7e7;
}

.login-2 .btn-lg {
    font-size: 17px;
    padding: 0 60px;
    line-height: 50px;
    /* width: 100%; */
}

.login-2 .btn{
    box-shadow: none!important;
}

.login-2 .btn-md{
    padding: 0 50px;
    font-size: 15px;
    line-height: 45px;
}

.login-2 .info{
    position: relative;
    width: 320px;
    margin: 0 auto;
    height: 320px;
}

.login-2 .info .box h3 {
    font-size: 26px;
    text-transform: capitalize;
    margin-bottom: 20px;
    font-weight: 600;
    color: #0a58a0;
}

.login-2 .info .box {
    position: absolute;
    width: 320px;
    margin: 0 auto;
    height: 320px;
    overflow: hidden;
    padding: 20px;
    border: solid 5px rgb(1 36 104 / 40%);
}

.login-2 .info .box .content{
    position:absolute;
    top:15px;
    left:15px;
    right:15px;
    bottom:15px;
    border: solid 1px rgb(1 36 104 / 29%);
    padding: 15px 20px;
}

.login-2 .info .box span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
}

.login-2 .info .box span:nth-child(1) {
    transform:rotate(0deg);
}

.login-2 .info .box span:nth-child(2) {
    transform:rotate(90deg);
}

.login-2 .info .box span:nth-child(3) {
    transform:rotate(180deg);
}

.login-2 .info .box span:nth-child(4) {
    transform:rotate(270deg);
}

.login-2 .info .box span:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: #023c9b;
    animation: animate 4s linear infinite;
}

@keyframes animate {
    0% {
        transform:scaleX(0);
        transform-origin: left;
    }
    50% {
        transform:scaleX(1);
        transform-origin: left;
    }
    50.1% {
        transform:scaleX(1);
        transform-origin: right;
    }

    100% {
        transform:scaleX(0);
        transform-origin: right;
    }
}

.login-2.login-background {
    background: url(../../assets/img/bg.jpg) no-repeat;
    background-size: cover;
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.login-2.login-background .cube {
    position: absolute;
    top: 80vh;
    right: 200px;
    width: 10px;
    height: 10px;
    border: solid 1px red;
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 6s ease-in forwards infinite;
}

.login-2.login-background .cube:nth-child(2n) {
    border-color: #00cad4;
}

.login-2.login-background .cube:nth-child(2) {
    animation-delay: 2s;
    right: 300px;
    border-color: yellow;
    top: 200px;
}

.login-2.login-background .cube:nth-child(3) {
    animation-delay: 3s;
    right: 50%;
    top: 150px;
    border-color: blue;
}

.login-2.login-background .cube:nth-child(4) {
    animation-delay: 4s;
    right: 30%;
    top: 150px;
}

.login-2.login-background .cube:nth-child(5) {
    animation-delay: 5s;
    right: 50%;
    bottom: 150px;
    border-color: black;
}

.login-2.login-background .cube:nth-child(6) {
    animation-delay: 6s;
    right: 10%;
    top: 50%;
    border-color: aquamarine;
}

/* Animate Background*/
@keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@keyframes cube {
    from {
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        opacity: 1;
    }
    to {
        transform: scale(20) rotate(960deg) translate(-50%, -50%);
        opacity: 0;
    }
}

/** Social media **/
.login-2 .facebook-bg{
    color: #4867aa;
}

.login-2 .facebook-bg:hover {
    background: #4867aa;
}

.login-2 .twitter-bg {
    color: #33CCFF;
}

.login-2 .twitter-bg:hover {
    background: #33CCFF;
}

.login-2 .google-bg {
    color: #db4437;
}

.login-2 .google-bg:hover {
    background: #db4437;
}

.login-2 .linkedin-bg {
    color: #2392e0;
}

.login-2 .linkedin-bg:hover {
    background: #1c82ca;
}

@media (max-width: 1200px) {
    .login-2 .form-section {
        padding: 70px 50px;
    }
}

@media (max-width: 992px) {
    .login-2 .form-section h3 {
        font-size: 20px;
    }

    .login-2 .logo-2 img {
        height: 25px;
    }

    .login-2 .form-info{
        width: 100%;
    }

    .login-2 .form-section {
        padding: 60px;
        border-radius: 10px;
    }

    .login-2 .bg-img{
        display: none!important;
    }

    .login-2 .login-box {
        max-width: 500px;
    }

    .login-2 .logo{
        display:inherit;
    }

    .login-2 .login-2-inner:before {
        display: none;
    }

    .login-2.login-background .cube:nth-child(2) {
        right: 100px;
        top: 100px;
    }

    .login-2.login-background .cube:nth-child(3) {
        left: 50px;
        top: 50px;
    }

    .login-2.login-background .cube:nth-child(4) {
        left: 100px;
        top: 350px;
    }

    .login-2.login-background .cube:nth-child(5) {
        left: 100px;
        bottom: 50px;
    }

    .login-2.login-background .cube:nth-child(6) {
        right: 50px;
        top: 5%;
    }
}

@media (max-width: 768px) {
    .container{
        width: 90%;
    }
    .login-2 .form-section{
        padding: 25px 30px;
    }
    .login-2.login-background{
        height: auto;
    }
    .login-2 .btn-section .link-btn{
        width: 100%;
        border: 1px solid #0a58a0 !important;
        border-radius: 5px;
        margin-bottom: 5px;
    }
    .login-2 .btn-section{
        width: 75%;
    }
}
/** Login 2 end **/

/** Login 3 start **/
.login-3-inner {
    position: relative;
    min-height: 100vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 30px 0;
}

.login-3-inner:before {
    content: "";
    width: 40%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    /* background: url(../../assets/img/img-3.jpg) top left repeat; */
    z-index: -99;
}

.login-3 .logo {
    top: 80px;
    position: absolute;
}

.login-3 .logo img {
    height: 30px;
}

.login-3 .form-info {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
}

.login-3 .form-section {
    padding: 160px 80px 60px;
    border-radius: 10px 0 0 10px;
    text-align: left;
    position: relative;
}

.login-3 label {
    color: #535353;
    font-size: 16px;
    margin-bottom: 5px;
}

.login-3 .form-section p{
    font-size: 16px;
    color: #535353;
}

.login-3 .form-section a{
    color: #535353;
    text-decoration: none;
    font-size: 16px;
}

.login-3 .form-section p {
    margin-bottom: 40px;
}

.login-3 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-3 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-3 .form-section .thembo {
    margin-left: 4px;
}

.login-3 .form-section h1 {
    font-size: 27px;
    font-weight: 600;
    color: #7878ff;
}

.login-3 .form-section h3 {
    margin: 0 0 35px;
    font-size: 23px;
    font-weight: 400;
    color: #040404;
}

.login-3 .form-section .form-group {
    margin-bottom: 25px;
}

.login-3 .form-section .form-control {
    padding: 10px 20px;
    font-size: 16px;
    outline: none;
    height: 50px;
    color: #535353;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid transparent;
    background: #fff5f5;
}

.login-3 .form-section .checkbox .terms {
    margin-left: 3px;
}

.login-3 .form-section .terms {
    margin-left: 3px;
}

.login-3 .btn-section {
    border-radius: 50px;
    margin-bottom: 0;
    display: inline-block;
    top: 80px;
    position: absolute;
    right: 90px;
}

.login-3 .btn-section .link-btn {
    font-size: 14px;
    float: left;
    text-align: center;
    width: 100px;
    padding: 6px 5px;
    margin-left: 5px;
    color: #535353;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.login-3 .btn-section .active-bg {
    color: #fff;
    background: #7878ff;
}

.login-3 .btn-section .link-btn:hover {
    color: #fff;
    background: #7878ff;
}

.login-3 .form-check-input:focus {
    box-shadow: none;
}

.login-3 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    border: 1px solid #fff5f5;
    border-radius: 3px;
    position: absolute;
    background-color: #fff5f5;
}

.login-3 .form-check-input:checked {
    background-color: #7878ff;
}

.login-3 .form-section .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-3 .btn-theme {
    color: #fff;
    text-align: center;
    border: 2px solid transparent;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: all .7s ease;
    border-radius: 3px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
}

.login-3 .btn-theme:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
    border-radius: 3px;
}

.login-3 .btn-theme:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
}

.login-3 .btn-theme:hover {
    background: transparent;
}

.login-3 .btn-theme:hover:before {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-3 .btn-theme:hover:after {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-3 .btn-lg{
    padding: 0 50px;
    line-height: 46px;
}

.login-3 .btn{
    box-shadow: none!important;
}

.login-3 .btn-md{
    padding: 0 45px;
    line-height: 41px;
}

.login-3 .btn-primary {
    background: #7878ff;
}

.login-3 .btn-primary:before {
    background: #7878ff;
}

.login-3 .btn-primary:after {
    background: #7878ff;
}

.login-3 .btn-primary:hover {
    color: #7878ff;
    border: 2px solid #7878ff;
}

.login-3 .social-list a {
    font-size: 18px;
    margin-right: 20px;
    color: #535353;
}

.login-3 .social-list a:hover {
    color: #7878ff;
}

.login-3 .login-3-bodycolor{
    background: #fff;
}

.login-3-bodycolor .ripple-background{
    z-index: -999!important;
}

.login-3-bodycolor .ripple-background .circle{
    position: absolute;
    border-radius: 50%;
    animation: ripple 15s infinite;
}

.login-3-bodycolor .ripple-background .small{
    width: 200px;
    height: 200px;
    left: -100px;
    top: -100px;
    z-index: -999;
}

.login-3-bodycolor .ripple-background .medium{
    width: 400px;
    height: 400px;
    left: -200px;
    top: -200px;
    z-index: -999;
}

.login-3-bodycolor .ripple-background .large{
    width: 600px;
    height: 600px;
    left: -300px;
    top: -300px;
    z-index: -999;
}

.login-3-bodycolor .ripple-background .xlarge{
    width: 800px;
    height: 800px;
    left: -400px;
    top: -400px;
    z-index: -999;
}

.login-3-bodycolor .ripple-background .xxlarge{
    width: 1000px;
    height: 1000px;
    left: -500px;
    top: -500px;
    z-index: -999;
}

.login-3-bodycolor .ripple-background .shade1{
    background: #0000ff26;
}

.login-3-bodycolor .ripple-background .shade2{
    background: #b9b9ff;
}

.login-3-bodycolor .ripple-background .shade3{
    background: #0000ff26;
}

.login-3-bodycolor .ripple-background .shade4{
    background: #0a58ca;
}

.login-3-bodycolor .ripple-background .shade5{
    background: #0000ff26;
}

@keyframes ripple{
    0%{
        transform: scale(0.8);
    }

    50%{
        transform: scale(1.2);
    }

    100%{
        transform: scale(0.8);
    }
}

@media (max-width: 992px) {
    .login-3-inner:before {
        background: none;
    }

    .login-3 .form-section {
        padding: 120px 34px 40px;
        border-radius: 10px 0 0 10px;
    }

    .login-3 .logo {
        top: 40px;
        left: 40px;
    }

    .login-3 .btn-section {
        top: 40px;
        right: 40px;
    }

    .login-3-bodycolor .ripple-background{
        display: none;
    }
}
/** Login 3 end **/

/** Login 4 start **/
.login-4 {
    background-image: linear-gradient(to bottom, #ff0000, #ff8100);
}

.login-4 .form-section {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.login-4 .login-4-inner:before {
    content: "";
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 20%;
    -webkit-clip-path: polygon(0 0, 100% 0, 0% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 100%, 0% 100%, 0% 100%);
    background-image: linear-gradient(to bottom, #ff8100, #ff0000);
}


.login-4 .form-section a{
    text-decoration: none;
}

.login-4 .form-inner {
    max-width: 450px;
    width: 100%;
    margin: 0 30px;
    text-align: center;
}

.login-4 .bg-img {
    /* background: url(../../assets/img/img-4.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    padding: 0;
}

.login-4 .form-section .extra-login {
    float: left;
    width: 100%;
    margin: 25px 0 25px;
    text-align: center;
    position: relative;
}

.login-4 .form-section .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #00000014;
    content: "";
}

.login-4 .form-section .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-size: 15px;
    color: #fff;
    text-transform: capitalize;
    background-image: linear-gradient(to bottom, #ff5d00, #ff5d00);
}

.login-4 .form-section p {
    color: #fff;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500;
}

.login-4 .form-check-input:focus {
    border-color: #2c3ca9;
    box-shadow: none;
}

.login-4 .form-check-input:checked {
    background-color: #2c3ca9;
    border-color: #2c3ca9 !important;
}

.login-4 .form-check {
    float: left;
    margin: 0;
    padding-left: 25px;
    font-size: 16px;
    color: #535353;
}

.login-4 .form-section p a {
    color: #fff;
}

.login-4 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-4 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-4 .login-box .alert{
    border-radius: 50px;
}

.login-4 .form-section .thembo {
    margin-left: 4px;
}

.login-4 .form-section h3 {
    margin: 0 0 30px;
    font-size: 25px;
    font-weight: 400;
    color: #fff;
}

.login-4 .form-section .form-group {
    margin-bottom: 25px;
}

.login-4 .form-section .form-box {
    float: left;
    width: 100%;
    text-align: left;
    position: relative;
}

.login-4 .form-section .form-control {
    padding: 11px 20px 9px;
    font-size: 16px;
    outline: none;
    height: 50px;
    color: #535353;
    border-radius: 50px;
    font-weight: 500;
    border: 1px solid transparent;
    background: #fff;
}

.login-4 .form-section .checkbox .terms {
    margin-left: 3px;
}

.login-4 .none-2 {
    display: none;
}

.login-4 .form-section .terms {
    margin-left: 3px;
}

.login-4 .form-section .checkbox {
    font-size: 14px;
}

.login-4 .form-section .form-check a {
    color: #fff;
    float: right;
}

.login-4 .form-section .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 15px;
    color: #fff;
}

.login-4 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    position: absolute;
    border: none;
    border-radius: 3px;
}

.login-4 .form-section .checkbox-theme input[type="checkbox"]:checked + label::before {
    background: #2c3ca9;
    border: 1px solid #2c3ca9!important;
}

.login-4 .form-section a.forgot-password {
    font-size: 15px;
    color: #fff;
    line-height: 50px;
}

.login-4 .logo img {
    margin-bottom: 15px;
    height: 30px;
}

.login-4 .clip-home {
    clip-path: polygon(0 0, 100% 0, 100% 0%, 100% 100%, 20% 100%);
    position: relative;
}

.login-4 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-4 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-4 .form-section .social-list li a {
    font-size: 14px;
    font-weight: 400;
    width: 130px;
    margin: 2px 0 3px 0;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.login-4 .form-section .social-list li a i {
    height: 40px;
    width: 40px;
    line-height: 40px;
    float: left;
    color: #fff;
    font-size: 14px;
    border-radius: 20px;
}

.login-4 .form-section .social-list li a span {
    margin-right: 7px;
}

.login-4 .btn-theme {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 50px;
    float: left;
}

.login-4 .btn-theme:hover {
    color: #2c3ca9;
}

.login-4 .btn-theme:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.login-4 .btn-theme:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    content: "";
    border-radius: 7px;
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

.login-4 .login-box .form-group label.error{
    color: #fff;
}

.login-4 .btn-lg{
    padding: 0 50px;
    line-height: 50px;
}

.login-4 .btn{
    box-shadow: none!important;
}

.login-4 .btn-md{
    padding: 0 50px;
    line-height: 45px;
}

.login-4 .btn-primary{
    background: #2c3ca9;
}

/** Social media **/
.login-4 .facebook-color {
    color: #4867aa;
}

.login-4 .twitter-color {
    color: #33CCFF;
}

.login-4 .google-color {
    color: #db4437;
}

.login-4 .twitter-i {
    background: #33CCFF;
}

.login-4 .facebook-i {
    background: #4867aa;
}

.login-4 .google-i {
    background: #db4437;
}

@media (max-width: 992px) {
    .login-4 .bg-img {
        min-height: 100%;
    }
}

@media (max-width: 768px) {
    .login-4 .form-inner {
        margin: 0 15px;
    }
}
/** Login 4 end **/

/** Login 5 start **/


.login-5 {
    /* background: url(../../assets/img/img-31.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    width: 100%;
    text-align: center;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.login-5 .form-section a{
    text-decoration: none;
}

.login-5 .form-inner {
    max-width: 570px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 70px 90px;
    background: #fff;
    position: relative;
    z-index: 0;
    box-shadow: 0 0 35px rgb(0 0 0 / 10%);
}

.login-5 .form-inner:before {
    content: "";
    width: 75px;
    height: 150px;
    position: absolute;
    top: 30px;
    right: 0;
    /* background: url(../../assets/img/img-54.png) top left repeat; */
    background-size: cover;
    z-index: -1;
}

.login-5 .form-inner:after {
    content: "";
    width: 75px;
    height: 150px;
    position: absolute;
    bottom: 30px;
    left: 0;
    /* background: url(../../assets/img/img-53.png) top left repeat; */
    background-size: cover;
    z-index: -1;
}

.login-5 .form-section .extra-login {
    float: left;
    width: 100%;
    text-align: center;
    position: relative;
}

.login-5 .form-section .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #e4e4e4;
    content: "";
}

.login-5 .form-section .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-size: 14px;
    color: #535353;
    text-transform: capitalize;
    background: #fff;
}

.login-5 .form-section p {
    color: #535353;
    margin-bottom: 0;
    font-size: 16px;
}

.login-5 .form-section p a {
    color: #535353;
}

.login-5 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-5 .form-section .thembo {
    margin-left: 4px;
}

.login-5 .form-section h3 {
    margin: 0 0 25px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-5 .form-section .form-group {
    margin-bottom: 25px;
}

.login-5 .form-section .form-box {
    float: left;
    width: 100%;
    text-align: left;
    position: relative;
}


.login-5 .form-section .form-control {
    padding: 14.5px 0;
    font-size: 16px;
    outline: none;
    background: transparent!important;
    color: #535353;
    font-weight: 500;
    border-radius: 0;
    border: none;
    border-bottom: solid 2px #bdbdbd;
}

.login-5 .form-section .form-box i {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 23px;
    color: #777575;
}

.login-5 .form-section .checkbox .terms {
    margin-left: 3px;
}

.login-5 .form-section .terms {
    margin-left: 3px;
}

.login-5 .form-section .form-check {
    float: left;
    margin-bottom: 0;
    padding-left: 25px;
    font-size: 16px;
    color: #535353;
}

.login-5 .form-section .form-check .form-check-input {
    margin-left: -25px;
}

.login-5 .form-section .form-check a {
    color: #535353;
}

.login-5 .form-check-input:focus {
    border-color: #ff013d;
    outline: 0;
    box-shadow: none;
}

.login-5 .form-check-input:checked {
    background-color: #00c5e7;
    border-color: #00c5e7!important;
}

.login-5 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    vertical-align: top;
    position: absolute;
    border: 2px solid #c5c3c3;
    border-radius: 0;
}

.login-5 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
    float: right;
}

.login-5 .logo img {
    margin-bottom: 15px;
    height: 25px;
}

.login-5 .btn-theme {
    position: relative;
    display: inline-block;
    width: 100%;
    color: #ffffff;
    overflow: hidden;
    overflow: hidden;
    text-transform: capitalize;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    border: none;
}

.login-5 .btn-theme:hover {
    color: #fff;
}

.login-5 .btn-theme:hover::before {
    left: 0%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.login-5 .btn-theme:before {
    position: absolute;
    content: '';
    left: 96%;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    transform: skewX(-25deg);
}

.login-5 .btn-theme span {
    position: relative;
    z-index: 1;
}

.login-5 .btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: none;
}

.login-5 .btn-lg{
    padding: 0 50px;
    line-height: 55px;
}

.login-5 .btn-md{
    padding: 0 45px;
    line-height: 50px;
}

.login-5 .btn{
    box-shadow: none!important;
}

.login-5 .btn-primary {
    background: #00c5e7;
}

.login-5 .btn-theme:before {
    background: #42b4e9;
}

.login-5 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-5 .social-list {
    padding: 0;
    text-align: center;
}

.login-5 .social-list li a {
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    bottom: 15px;
}

.login-5 .social-list li {
    display: inline-block;
}

.login-5 .social-list li a {
    margin: 1px;
    font-size: 14px;
    font-weight: 500;
    width: 110px;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    color: #fff;
}

.login-5 .social-list li a:hover {
    text-decoration: none;
}

.login-5 .facebook-bg {
    background: #4867aa;
}

.login-5 .facebook-bg:hover {
    background: #3d5996;
    color: #fff;
}

.login-5 .twitter-bg {
    background: #33CCFF;
}

.login-5 .twitter-bg:hover {
    background: #56d7fe;
}

.login-5 .google-bg {
    background: #db4437;
}

.login-5 .google-bg:hover {
    background: #dc4e41;
}

@media (max-width: 992px) {
    .login-5 .form-inner {
        padding: 50px 35px;
    }

    .login-5 .form-inner:before {
        width: 35px;
        height: 70px;
    }

    .login-5 .form-inner:after {
        width: 35px;
        height: 70px;
    }
}
/** Login 5 end **/

/** Login 6 start **/
.login-6 .login-inner-form {
    color: #e7e7e7;
    position: relative;
}

.login-6 .bg-color-6 {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 50px;
    background: #0b247ff7;
}


.login-6 .form-section{
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
}

.login-6 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-6 .login-inner-form .form-control {
    font-size: 15px;
    outline: none;
    color: #535353;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.login-6 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-6 .login-inner-form .form-box .form-control {
    float: left;
    width: 100%;
    padding: 13px 15px 13px 65px;
}

.login-6 .login-inner-form .form-box i {
    position: absolute;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #fea900;
    border-radius: 3px 0 0 3px;
    font-size: 20px;
    color: #fff;
}

.login-6 .info {
    color: #fff;
    margin: 0 117px 0 auto;
    text-align: right;
    max-width: 700px;
}

.login-6 .bg-img {
    top: 0;
    bottom: 0;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.login-6 .waviy {
    position: relative;
    -webkit-box-reflect: below -30px linear-gradient(transparent, rgba(0,0,0,.2));
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 70px;
}

.login-6 .waviy span {
    position: relative;
    display: inline-block;
    color: #353535;
    text-transform: uppercase;
    animation: waviy 1s infinite;
    animation-delay: calc(.1s * var(--i));
    font-family: 'Jost', sans-serif;
}

.login-6 .waviy span.color-yellow{
    color: #fea900;
}

@keyframes waviy {
    0%,40%,100% {
        transform: translateY(0)
    }
    20% {
        transform: translateY(-20px)
    }
}

.login-6 .info P{
    color: #535353;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 0;
}

.login-6 .login-inner-form .forgot{
    margin: 0;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    float: right;
}

.login-6 .bg-photo {
    bottom: 0;
    position: absolute;
}

.login-6 .btn-theme {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    width: 100%;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
}

.login-6 .login-inner-form p{
    margin: 0;
    color: #e7e7e7;
}

.login-6 .login-inner-form p a{
    color: #e7e7e7;
}

.login-46 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-6 .logo{
    text-align: center;
    margin-bottom: 15px;
}

.login-6 .logo img{
    height: 25px;
}

.login-6 h3{
    text-align: center;
    margin: 0 0 24px;
    font-size: 25px;
    color: #fea900;
    font-weight: 400;
}

.login-6 .form-check-input:checked {
    background-color: #fea900!important;
    border-color: #fea900!important;
}

.login-6 .login-inner-form .social-list{
    padding: 0;
    text-align: center;
}

.login-6 .login-inner-form .social-list li {
    display: inline-block;
}

.login-6 .login-inner-form .social-list li a {
    margin: 1px;
    font-size: 15px;
    font-weight: 400;
    width: 120px;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    color: #fff;
}

.login-6 .login-inner-form .social-list li a:hover{
    text-decoration: none;
}

.login-6 .login-inner-form .extra-login {
    float: left;
    width: 100%;
    margin: 25px 0;
    text-align: center;
    position: relative;
}

.login-6 .login-inner-form .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #475e8d9e;
    content: "";
}

.login-6 .login-inner-form .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    background: #132b83;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-size: 15px;
    color: #e7e7e7;
    text-transform: capitalize;
}

.login-6 .login-inner-form .terms{
    margin-left: 3px;
}

.login-6 .login-inner-form .checkbox {
    margin-bottom: 25px;
    font-size: 15px;
}

.login-6 .login-inner-form .form-check{
    margin-bottom: 0;
}

.login-6 .login-inner-form .form-check a {
    color: #e7e7e7;
}

.login-6 .login-inner-form .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 15px;
    color: #e7e7e7;
}

.login-6 .btn{
    box-shadow: none!important;
}

.login-6 .btn-lg{
    padding: 0 50px;
    line-height: 50px;
}

.login-6 .btn-md{
    padding: 0 50px;
    line-height: 45px;
}

.login-6 .btn-primary{
    background: #fea900;
}

.login-6 .btn-primary:hover {
    background: #de9503;
}

.login-6 .form-section a {
    text-decoration: none;
}

.login-6 .form-section .form-check-input {
    width: 18px;
    height: 18px;
    margin-top: 3px;
    position: absolute;
    border: 1px solid #fbf1f1;
    border-radius: 0;
    background-color: #fbf1f1;
    margin-left: -22px;
}

.login-6 .login-inner-form .checkbox a {
    font-size: 15px;
    font-weight: 400;
    color: #e7e7e7;
}

.login-6 .form-section p {
    margin: 28px 0 0;
    font-size: 15px;
    color: #e7e7e7;
    font-weight: 400;
}

.login-6 .form-section p a {
    color: #e7e7e7;
}

.login-6 .facebook-bg {
    background: #4867aa;
}

.login-6 .facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.login-6 .twitter-bg {
    background: #33CCFF;
}

.login-6 .twitter-bg:hover {
    background: #56d7fe;
}

.login-6 .google-bg {
    background: #db4437;
}

.login-6 .google-bg:hover {
    background: #dc4e41;
}

/** MEDIA **/
@media (max-width: 1200px) {
    .login-6 .info {
        margin: 0 50px;
    }

    .login-6 .waviy {
        font-size: 33px;
        margin-bottom: 30px;
    }
}

@media (max-width: 992px) {
    .login-6 .bg-img{
        display: none;
    }

    .login-6 .login-inner-form .social-list li a {
        width: 100px;
        height: 35px;
        line-height: 35px;
        font-size: 14px;
    }

    .login-6 .bg-color-6 {
        padding: 30px 15px;
    }
}
/** Login 6 end **/

/** Login 7 start **/

.login-7 {
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.login-7 .form-section{
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    background: #fff;
    padding: 50px;
    border-radius: 30px;
    box-shadow: 0 0 35px rgb(0 0 0 / 10%);
    text-align: left;
    position: relative;
    z-index: 0;
}

.login-7 .form-section:before {
    content: "";
    width: 100%;
    height: 168px;
    position: absolute;
    top: 0;
    right: 0;
    /* background: url(../../assets/img/img-55.png) top left repeat; */
    background-size: cover;
    z-index: -1;
    border-radius: 30px 30px 0 0;
}

.login-7 .form-box{
    width: 100%;
}

.login-7 .login-inner-form .form-group {
    margin-bottom: 35px;
}

.login-7 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-7 .login-inner-form .form-control {
    padding: 14.5px 0;
    font-size: 16px;
    outline: none;
    background: transparent!important;
    color: #535353;
    font-weight: 500;
    border: none;
    border-bottom: solid 2px #bdbdbd;
    border-radius: 0;
}

.login-7 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-7 .login-inner-form .form-box .form-control {
    float: left;
    width: 100%;
    padding: 13px 15px 13px 30px;
}

.login-7 .login-inner-form .form-box i {
    position: absolute;
    top: 8px;
    left: 0;
    font-size: 23px;
    color: #777575;
}

.login-7 .info {
    color: #fff;
    margin: 0 117px 0 auto;
    text-align: right;
    max-width: 700px;
}

.login-7 .bg-img {
    top: 0;
    bottom: 0;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-7 .login-inner-form .forgot{
    margin: 0;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    float: right;
}

.login-7 .btn-theme {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    width: 100%;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
}

.login-7 .login-inner-form p{
    margin: 0;
    color: #e7e7e7;
}

.login-7 .login-inner-form p a{
    color: #e7e7e7;
}

.login-46 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-7 .logo{
    margin-bottom: 15px;
}

.login-7 .logo img{
    height: 25px;
}

.login-7 h3{
    margin: 0 0 35px;
    font-size: 23px;
    color: #040404;
    font-weight: 400;
}

.login-7 .form-check-input:checked {
    background-color: #039ae0!important;
    border-color: #039ae0!important;
}

.login-7 .login-inner-form .terms{
    margin-left: 3px;
}

.login-7 .login-inner-form .checkbox {
    margin-bottom: 35px;
    font-size: 15px;
}

.login-7 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-7 .login-inner-form .form-check a {
    color: #535353;
    float: right;
}

.login-7 .login-inner-form .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-7 .btn{
    box-shadow: none!important;
}

.login-7 .btn-lg{
    padding: 0 50px;
    line-height: 50px;
}

.login-7 .btn-md{
    padding: 0 50px;
    line-height: 45px;
}

.login-7 .btn-primary{
    background: #039ae0;
}

.login-7 .btn-primary:hover {
    background: #048dcd;
}

.login-7 .form-section a {
    text-decoration: none;
}

.login-7 .form-section .form-check-input {
    width: 18px;
    height: 18px;
    margin-top: 1px;
    position: absolute;
    border: 2px solid #bdbdbd;
    border-radius: 0;
    background-color: #fff;
    margin-left: -22px;
}

.login-7 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #535353;
    float: right;
}

.login-7 .form-section p {
    font-size: 16px;
    color: #535353;
    text-align: left;
    margin-bottom: 0;
}

.login-7 .form-section p a {
    color: #535353;
}

.login-7 .social-list{
    margin-bottom: 33px;
}

.login-7 .social-list a {
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    font-size: 18px;
    display: inline-block;
    color: rgb(255, 255, 255);
    margin: 0 2px 2px 0;
    border-radius: 50px;
}

.login-7 .facebook-bg {
    background: #4867aa;
}

.login-7 .facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.login-7 .twitter-bg {
    background: #33CCFF;
}

.login-7 .twitter-bg:hover {
    background: #56d7fe;
}

.login-7 .google-bg {
    background: #db4437;
}

.login-7 .google-bg:hover {
    background: #dc4e41;
}

.login-7 .linkedin-bg {
    background: #2392e0;
}

.login-7 .linkedin-bg:hover {
    background: #1c82ca;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-7 .form-section {
        padding: 50px 30px;
    }
}
/** Login 7 end **/

/** Login 8 start **/
.login-8{
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    background: #fff5f5;
}

.login-8 .container{
    max-width: 1120px;
    margin: 0 auto;
}

.login-8 a {
    text-decoration: none;
}

.login-8 .info-text{
    text-align: right;
    top: 35%;
    position: absolute;
    right: 30px;
    max-width: 350px;
    margin-left: auto;
}

.login-8 .waviy {
    position: relative;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
}

.login-8 .waviy span {
    position: relative;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    animation: waviy 1s infinite;
    animation-delay: calc(.1s * var(--i));
    font-family: 'Jost', sans-serif;
}

.login-8 .waviy span.color-yellow{
    color: #000;
}

@keyframes waviy {
    0%,40%,100% {
        transform: translateY(0)
    }
    20% {
        transform: translateY(-20px)
    }
}

.login-8 p{
    color: #fff;
    margin-bottom: 0;
    font-size: 15px;
}

.login-8 .form-check-input:checked {
    display: none;
}

.login-8 .form-info {
    justify-content: center;
    align-items: center;
    padding: 100px 90px;
}

.login-8 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-8 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-8 .login-inner-form .form-control {
    font-size: 16px;
    outline: none;
    color: #535353;
    border-radius: 100px;
    border: 1px solid #d9d9d9;
    padding: 14.5px 45px 14.5px 20px;
}

.login-8 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-8 .login-inner-form .form-box i {
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: 20px;
    color: #535353;
}

.login-8 .login-inner-form .forgot{
    margin: 0;
    line-height: 45px;
}

.login-8 .bg-img {
    /* background: url(../../assets/img/img-35.png) top left repeat; */
    background-size: cover;
    top: 0;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    position: relative;
    display: flex;
}

.login-8 .login-box {
    background: #fff;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-8 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    color: #fff;
    background: #ff4d62;
    border: solid 1px #ff4d62;
}

.login-8 .login-inner-form .btn-md {
    cursor: pointer;
    height: 55px;
    color: #fff;
    padding: 13px 50px 12px 50px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 100px;
    text-transform: uppercase;
}

.login-8 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-8 .login-inner-form p{
    margin: 0;
    color: #535353;
}

.login-8 .login-inner-form p a{
    color: #535353;
}

.login-8 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-8 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-8 .login-inner-form .btn-theme {
    background: #ff4d62;
    border: none;
    color: #fff;
}

.login-8 .login-inner-form .btn-theme:hover {
    background: #ef3f54;
}

.login-8 .logo img{
    margin-bottom: 15px;
    height: 30px;
}

.login-8 .nav-pills li{
    display: inline-block;
}

.login-8 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
    padding-left: 0;
}

.login-8 .login-inner-form .form-check a {
    color: #d6d6d6;
    float: right;
}

.login-8 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-8 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 1px;
    margin-left: -22px;
    border-radius: 100px;
    background: #fff;
    border: 1px solid #d9d9d9;
}

.login-8 .login-inner-form .form-check-label {
    padding-left: 20px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-8 .form-section input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #e6e6e6;
    line-height: 18px;
    font-size: 12px;
    content: "\2713";
}

.login-8 .btn-section{
    float: right;
    display: inline-block;
}

.login-8 .btn-section {
    top: 30px;
    position: absolute;
    right: 30px;
    margin-bottom: 0;
}

.login-8 .btn-section .link-btn {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #eb3c50;
    padding: 10px 18px;
    text-decoration: none;
    text-decoration: blink;
    text-transform: uppercase;
    border-radius: 3px;
    background: #fff;
}

.login-8 .btn-section .link-btn:hover{
    background: #eb3c50;
    color: #fff;
}

.login-8 .btn-section .link-btn.active{
    background: #eb3c50;
    color: #fff;
}

.login-8 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-8 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #535353;
    float: right;
    margin-left: 3px;
}

.login-8 .form-section{
    text-align: center;
}

.login-8 .form-section h3{
    font-size: 25px;
    margin-bottom: 30px;
    font-weight: 400;
    color: #040404;
}

.login-8 .form-section .text {
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 0;
    color: #535353;
}

.login-8 .form-section .text a{
    color: #535353;
}

.login-8 .social-list{
    bottom: 28px;
    position: absolute;
    right: 30px;
    padding: 0;
    margin: 0;
    list-style: none;
}

.login-8 .social-list li{
    display: inline-block;
}

.login-8 .social-list a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin: 0 2px 2px 0;
    text-align: center;
    display: inline-block;
    font-size: 17px;
    color: #ff4d62;
    background: #fff;
    border-radius: 50px;
}

.login-8 .social-list a:hover{
    color: #fff;
}

.login-8 .facebook-bg:hover {
    background: #3b589e;
}

.login-8 .twitter-bg:hover {
    background: #56d7fe;
}

.login-8 .google-bg:hover {
    background: #dc4e41;
}

.login-8 .linkedin-bg:hover {
    background: #1c82ca;
}

.login-8 .pinterest-bg:hover {
    background: #a30618;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-8 .bg-img{
        display: none;
    }

    .login-8 .form-info{
        padding: 50px 30px;
    }

    .login-8 .login-box{
        max-width: 600px;
        margin: 0 auto;
    }
}
/** Login 8 end **/

/** Login 9 start **/
.login-9 {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.login-9 .form-section {
    max-width: 600px;
    margin: 0 auto;
    /* background: url(../../assets/img/img-9.jpg) top left repeat; */
    background-size: cover;
    text-align: center;
    padding: 70px;
    border-radius: 10px;
    z-index: 999;
    position: relative;
}

.login-9 .form-section:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -999;
    border-radius: 10px;
    background: rgb(33 9 90 / 53%);
}


.login-9 .form-section a {
    text-decoration: none;
}

.login-9 .form-section p{
    color: #e2e1e1;
    margin-bottom: 0;
    font-size: 16px;
}

.login-9 .form-section p a{
    color: #e2e1e1;
}

.login-9 .form-section ul{
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-9 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-9 .logo-2 img{
    margin-bottom: 20px;
    height: 25px;
}

.login-9 .form-section .thembo{
    margin-left: 4px;
}

.login-9 .form-section h3 {
    margin: 0 0 30px;
    font-size: 25px;
    font-weight: 400;
    color: #e2e1e1;
}

.login-9 .form-section .form-group {
    margin-bottom: 30px;
}

.login-9 .form-section .form-control {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    color: #535353;
    border-radius: 3px;
    height: 50px;
    border: 1px solid #fff;
    background: #fff;
}

.login-9 .form-section label{
    color: #e2e1e1;
    font-size: 16px;
}

.login-9 .form-section .checkbox .terms{
    margin-left: 3px;
}

.login-9 .form-section .terms{
    margin-left: 3px;
}

.login-9 .btn-section {
    border-radius: 50px;
    display: inline-block;
    margin-bottom: 25px;
}

.login-9 .btn-section .link-btn {
    font-size: 15px;
    float: left;
    background: transparent;
    letter-spacing: 0.5px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    margin: 0 5px 5px;
    text-decoration: blink;
    line-height: 35px;
    width: 100px;
    color: #c1c1c1;
    border-radius: 3px;
}

.login-9 .btn-section .link-btn:hover{
    color: #fff;
}

.login-9 .btn-section .default-bg {
    background: #fff;
    color: #000000;
}

.login-9 .btn-section .default-bg:hover{
    color: red;
}

.login-9 .btn-section .active-bg {
    background: #fff;
    color: red;
}

.login-9 .btn-section .active-bg:hover {
    color: red;
}

.login-9 .form-section .checkbox {
    font-size: 16px;
}

.login-9 .form-section .form-check{
    float: left;
    margin-bottom: 0;
}

.login-9 .form-section .form-check a {
    color: #e2e1e1;
}

.login-9 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    position: absolute;
    margin-left: -22px;
    border: none;
    border-radius: 2px;
}

.login-9 .form-check-input:focus {
    box-shadow: none;
}

.login-9 .form-check-input:checked {
    background-color: red;
    border-color: red;
}

.login-9 .form-section .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #fff;
}

.login-9 .form-section a.forgot-password {
    font-size: 16px;
    color: #e2e1e1;
    float: right;
}

.login-9 .social-list{
    float: left;
}

.login-9 .social-list span {
    margin-right: 5px;
    font-weight: 500;
    font-size: 16px;
    color: #e2e1e1;
}

.login-9 .social-list a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    display: inline-block;
    font-size: 17px;
    color: rgb(255, 255, 255);
    margin: 0 2px 2px 0;
    border-radius: 5%;
}

.login-9 .btn-theme {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    color: #fff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    width: 100%;
    float: left;
}

.login-9 .btn-theme:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.login-9 .btn-theme:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    content: "";
    border-radius: 7px;
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

.login-9 .btn-lg{
    padding: 0 50px;
    line-height: 50px;
}

.login-9 .btn{
    box-shadow: none!important;
}

.login-30 .btn-md{
    padding: 0 45px;
    line-height: 50px;
}

.login-9 .btn-primary {
    background: red;
}

.login-9 .btn-primary:hover{
    color: red;
}

/** Social media **/
.login-9 .facebook-bg{
    background: #4867aa;
}

.login-9 .facebook-bg:hover {
    background: #3b589e;
}

.login-9 .twitter-bg {
    background: #33CCFF;
}

.login-9 .twitter-bg:hover {
    background: #2abdef;
}

.login-9 .google-bg {
    background: #db4437;
}

.login-9 .google-bg:hover {
    background: #dc4e41;
}

.login-9 .linkedin-bg {
    background: #2392e0
}

.login-9 .linkedin-bg:hover {
    background: #1c82ca;
    color: #fff;
}

.login-9 {
    background: radial-gradient(ellipse at center, #fffeea 0%, #fffeea 35%, #B7E8EB 100%);
    overflow: hidden;
}

.login-9 .ocean {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #015871;
}

.login-9 .wave {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
}

.login-9 .wave:nth-of-type(2) {
    top: -175px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}

@keyframes swell {
    0%, 100% {
        transform: translate3d(0, -25px, 0);
    }
    50% {
        transform: translate3d(0, 5px, 0);
    }
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-9 .form-section {
        padding: 50px 30px;
    }
}
/** Login 9 end **/

/** Login 10 start **/
.login-10 {
    z-index: 999;
}

.login-10 .form-section {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    background: #fbfbfb;
    z-index: 999;
}

.login-10 .form-section a{
    text-decoration: none;
}

.login-10 .form-inner {
    max-width: 450px;
    width: 100%;
    margin: 0 15px;
    text-align: center;
    padding: 50px 30px;
    z-index: 1;
    background: #fff;
}

.login-10 .bg-img {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 50px;
    /* background: url(../../assets/img/img-10.svg); */
    z-index: 999;
}

.login-10 .info {
    z-index: 999;
}

.login-10 .form-section .extra-login {
    float: left;
    width: 100%;
    text-align: center;
    position: relative;
}

.login-10 .form-section .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #e4e4e4;
    content: "";
}

.login-10 .form-section .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-size: 14px;
    color: #535353;
    text-transform: capitalize;
    background: #fff;
}

.login-10 .form-section p {
    color: #535353;
    margin-bottom: 0;
    font-size: 16px;
}

.login-10 .form-section p a {
    color: #535353;
}

.login-10 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-10 .form-section .thembo {
    margin-left: 4px;
}

.login-10 .form-section h3 {
    margin: 0 0 25px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-10 .form-section .form-group {
    margin-bottom: 25px;
}

.login-10 .form-section .form-box {
    position: relative;
}

.login-10 .form-section .form-control {
    padding: 14.5px 20px;
    font-size: 16px;
    outline: none;
    color: #535353;
    border-radius: 3px;
    border: 1px solid #dadadae8;
    background: #fff;
}

.login-10 .form-section img {
    margin-bottom: 5px;
    height: 40px;
}

.login-10 .form-section .form-box i {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 23px;
    color: #777575;
}

.login-10 .form-section .checkbox .terms {
    margin-left: 3px;
}

.login-10 .form-section .terms {
    margin-left: 3px;
}

.login-10 .form-section .form-check {
    float: left;
    margin-bottom: 0;
    padding-left: 25px;
    font-size: 16px;
    color: #535353;
}

.login-10 .form-section .form-check .form-check-input {
    margin-left: -25px;
}

.login-10 .form-check-input:focus {
    border-color: #ff013d;
    outline: 0;
    box-shadow: none;
}

.login-10 .form-check-input:checked {
    background-color: #ff013d;
    border-color: #ff013d!important;
}

.login-10 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    vertical-align: top;
    position: absolute;
    border: 1px solid #c5c3c3;
    border-radius: 3px;
}

.login-10 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
}

.login-10 .logo img {
    margin-bottom: 15px;
    height: 25px;
}

.login-10 .info {
    max-width: 610px;
    padding: 10px 30px;
}

.login-10 .info h1 {
    margin-bottom: 20px;
    font-size: 45px;
    font-weight: 700;
    text-transform: uppercase;
}

.login-10 .info p {
    line-height: 28px;
    color: #535353;
}

.login-10 .btn-theme {
    position: relative;
    display: inline-block;
    width: 100%;
    color: #ffffff;
    overflow: hidden;
    overflow: hidden;
    text-transform: capitalize;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    border: none;
}

.login-10 .btn-theme:hover {
    color: #fff;
}

.login-10 .btn-theme:hover::before {
    left: 0%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.login-10 .btn-theme:before {
    position: absolute;
    content: '';
    left: 96%;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    transform: skewX(-25deg);
}

.login-10 .btn-theme span {
    position: relative;
    z-index: 1;
}

.login-10 .btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: none;
}

.login-10 .btn-lg{
    padding: 0 50px;
    line-height: 55px;
}

.login-10 .btn-md{
    padding: 0 45px;
    line-height: 50px;
}

.login-10 .btn{
    box-shadow: none!important;
}

.login-10 .btn-primary {
    background: #ff013d;
}

.login-10 .btn-theme:before{
    background: #e60238;
}

.login-10 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-10 .social-list {
    padding: 0;
    text-align: center;
}

.login-10 .social-list li a {
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    bottom: 15px;
}

.login-10 .social-list li {
    display: inline-block;
}

.login-10 .social-list li a {
    margin: 1px;
    font-size: 14px;
    font-weight: 500;
    width: 110px;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    color: #fff;
}

.login-10 .social-list li a:hover {
    text-decoration: none;
}

.login-10 .facebook-bg {
    background: #4867aa;
}

.login-10 .facebook-bg:hover {
    background: #3d5996;
    color: #fff;
}

.login-10 .twitter-bg {
    background: #33CCFF;
}

.login-10 .twitter-bg:hover {
    background: #56d7fe;
}

.login-10 .google-bg {
    background: #db4437;
}

.login-10 .google-bg:hover {
    background: #dc4e41;
}

:root{
    --animation-duration:1s;
    --bounce-height:calc(205px - 100vh);
}

.login-10 {
    overflow: hidden;
}

.login-10 #suport{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align:center;
}

.login-10 #smash{
    animation-name: smash;
    animation-duration: var(--animation-duration);
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform-origin: bottom;
}
.login-10 #translateShadow,#rotateImg,#light{
    border-radius: 50%;
    display:inline-block;
    width: 200px;
    height: 200px;
}
.login-10 #light{
    background-image:  radial-gradient(circle at 20% 20%, rgba(255, 221, 179,.7),rgba(255, 221, 179,.5) 10%, transparent 30% ,rgba(83,42,0,.8) 85%);
    position:absolute;
    z-index: 1;
}
.login-10 #translateShadow{
    animation-name: translateShadow;
    animation-duration: var(--animation-duration);
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
}

.login-10 #rotateImg{
    background-image: url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='75 75 251 251' fill='rgb(83,42,0)'><path d='M313.3,145.3c-14.4-29.3-38.8-50.5-67.1-61.6c-4.6-1.7-16.2-5.1-21.3-6.3	c-26.3-5.3-54.5-2.3-80.4,10.4c-30.2,14.8-52.9,40.6-63.8,72.5c-10.9,31.8-8.7,66,6.2,96.1l0.1,0.2c2.1,4.2,4.4,8.3,6.9,12.2l0,0	c0,0,0,0,0,0c23.6,36.9,64.4,58,106.5,58c18.7,0,37.6-4.1,55.4-12.9c30.2-14.8,52.9-40.6,63.8-72.5 	C330.3,209.6,328.1,175.5,313.3,145.3z M238.3,86.2c25.2,12.5,41.2,36.6,46,68.5c-24.3,0.8-45.4-15.7-67.4-33.1 	c-18.4-14.5-37.3-29.3-58.9-34.2C184.6,77.6,212.8,77.7,238.3,86.2z M85.4,161.9c10.4-30.6,32.2-55.3,61.2-69.6 	c0.9-0.4,1.7-0.8,2.6-1.2c23.6,2.3,44.4,18.6,64.6,34.5c21.4,16.9,43.4,34.1,68.8,34.1c0.8,0,1.5,0,2.3-0.1c1,9.5,1.1,19.7,0.2,30.4 	c-9.8-3.3-19.3-5.9-25.7-7.6c-37.6-9.8-79.7-16.1-112.5-16.7c-24-0.5-53,1.8-66,14.2C81.8,173.9,83.4,167.8,85.4,161.9z M91.4,254.4 	l-0.1-0.2c-10.1-20.5-14.1-42.8-11.9-65l0.5,0.2c5.3-12.9,29-19.5,66.7-18.8c32.5,0.7,74.1,6.8,111.4,16.6 	c10.1,2.6,18.9,5.3,26.5,7.8c-1.2,10.2-3.3,20.4-6.3,30.3c-19.8-8.8-48.7,2.6-81.8,15.7c-32,12.6-68.1,26.8-99.7,23 	C94.9,261,93.1,257.7,91.4,254.4z M187.8,321.2c-34.6-3.6-67-21.9-87.5-51.7c31.8,2.4,66.9-11.4,98-23.7 	c32.4-12.8,60.6-23.9,78.4-15.5c-5.6,17.2-13.6,33.4-23.5,47c-12.8,17.7-34.5,39.5-65.6,43.3L187.8,321.2z M253.5,309.4 	c-15.6,7.7-32.1,11.6-48.4,12.3c36.2-12.4,62.8-49,76.1-88.8c9.1,6.7,14.2,20.1,15.8,40.6C285.7,288.6,270.9,300.9,253.5,309.4z 	 M314.8,239.8c-3.3,9.8-7.8,19-13.3,27.4c-2.4-19.7-8.5-32.6-18.7-39.3c3.1-10.3,5.4-20.8,6.6-31.1c19.2,6.9,29.2,13.2,30.7,17.3 	l0.4-0.2C319.6,222.6,317.7,231.3,314.8,239.8z M290,191.7c1.1-11.3,1-22.1-0.2-32.4c6.8-0.9,13.8-3,21.1-6.8l-2.3-4.5 	c-6.8,3.4-13.2,5.4-19.5,6.3c-1.6-11.1-4.6-21.4-8.8-30.7c-4.6-10.3-10.8-19.2-18.3-26.6c19.5,11.6,36,28.7,46.8,50.5 	c9.3,18.9,13.4,39.4,12.3,59.8C314.9,201.6,302.6,196.1,290,191.7z'/></svg>");
    background-color: darkorange;
    background-size: 100%;
    animation-name: rotateImg;
    animation-duration: calc(var(--animation-duration) * 5.3);
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes translateShadow {
    0% {transform: translateY(0) ;box-shadow: 40px 40px  10px #444466}
    100% {transform: translateY(var(--bounce-height));box-shadow:40px 350px  250px #444466}
}

@keyframes smash{
    0% {transform: scaleY(.7)}
    15%  {transform: scaleY(1)}
}

@keyframes rotateImg {
    100% {transform:rotate(360deg)}
}

.login-10 .animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

@media (max-width: 992px) {
    .login-10 .bg-img {
        display: none;
    }
}
/** Login 10 end **/

/** Login 11 start **/
.login-11 .login-inner-form {
    color: #cccccc;
    position: relative;
}

.login-11 .form-info {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
    background: #fbf1f1;
}


.login-11 .form-section {
    max-width: 550px;
    margin: 0 auto;
    width: 100%;
}

.login-11 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-11 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-11 .login-inner-form .form-control {
    font-size: 16px;
    outline: none;
    color: #535353;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid transparent;
    background: #fbf1f1;
}

.login-11 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-11 .login-inner-form .form-box input {
    float: left;
    width: 100%;
    padding: 14.5px 50px 14.5px 25px;
    border-radius: 3px;
}

.login-11 .login-inner-form .form-box i {
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: 20px;
    color: #535353;
}

.login-11 .login-inner-form label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
}

.login-11 .login-inner-form .forgot {
    margin: 0;
    line-height: 45px;
    color: #535353;
    font-size: 15px;
    float: right;
}

.login-11 .info {
    max-width: 400px;
    margin: 0 auto;
    padding: 50px 40px;
    border: solid 5px #fff;
    text-align: center;
    margin-bottom: 30px;
    -webkit-transition: all 0.5s;
    transition: all 0.9s;
    position: relative;
    border-radius: 4px;
    z-index: 1;
}

.login-11 .info h1 {
    font-size: 28px;
    color: #fff;
    margin-bottom: 20px;
    text-transform: uppercase;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.login-11 .info p {
    margin-bottom: 0;
    color: #fff;
    line-height: 28px;
    opacity: 0.9;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.login-11 .info::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    border-radius: 0;
    -webkit-transition: all 0.5s;
    transition: all 0.9s;
    z-index: -1;
}

.login-11:hover .info::before {
    height: 100%;
}

.login-11 .info::before{
    background: #fff;
}

.login-11:hover .info p{
    color: #535353;
}

.login-11:hover .info h1{
    color: #f78c30;
}

.login-11 .login-inner-form p {
    margin: 0;
    color: #535353;
}

.login-11 .login-inner-form p a {
    color: #535353;
}

.login-11 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-11 .form-section a {
    text-decoration: none;
}

.login-11 .logo img {
    height: 25px;
    margin-bottom: 20px;
}

.login-11 .nav-pills li {
    display: inline-block;
}

.login-11 .login-inner-form .form-check {
    float: left;
    margin-bottom: 0;
}

.login-11 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-11 .login-inner-form .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-11 .btn-section {
    top: 20px;
    position: absolute;
    right: 20px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
}

.login-11 .btn-section .link-btn {
    font-size: 14px;
    float: left;
    background: transparent;
    letter-spacing: 0.5px;
    text-align: left;
    font-weight: 400;
    border-radius: 0;
    color: #f78c30;
    text-decoration: blink;
    font-family: 'Jost', sans-serif;
    text-transform: uppercase;
}

.login-11 .btn-section .btn-2 {
    line-height: 40px;
    text-align: center;
    border-radius: 0 50px 50px 0;
    width: 110px;
}

.login-11 .btn-section .active-bg {
    background: #f78c30;
    color: #fff;
}

.login-11 .btn-section .default-bg {
    background: #fff;
}

.login-11 .btn-section .btn-1 {
    line-height: 40px;
    text-align: center;
    width: 110px;
    border-radius: 50px 0 0 50px;
}

.login-11 .btn-section {
    margin: 0 auto 30px;
}

.login-11 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #535353;
    margin-left: 3px;
}

.login-11 .form-section {
    text-align: center;
    padding: 60px 60px;
    background: #fff;
    border-radius: 5px;
}

.login-11 .form-section h3 {
    font-size: 25px;
    margin-bottom: 35px;
    font-weight: 400;
    color: #040404;
}

.login-11 .btn-theme {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    width: 100%;
    font-family: 'Jost', sans-serif;
    border-radius: 50px;
}

.login-11 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    position: absolute;
    border: 1px solid #fbf1f1;
    border-radius: 0;
    background-color:#fbf1f1;
    margin-left: -24px;
}

.login-11 .form-check-input:checked {
    background-color: #f78c30;
    border-color: #f78c30;
}

.login-11 .btn-theme i::before {
    position: relative;
    font-size: 18px;
    top: 3px;
    padding-left: 5px;
}

.login-11 .btn-theme:hover {
    color: #fff;
}

.login-11 .btn-theme:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.login-11 .btn-theme:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    border-radius: 7px;
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

.login-11 .btn-lg{
    padding: 0 50px;
    line-height: 55px;
}

.login-11 .btn{
    box-shadow: none!important;
}

.login-11 .btn-md{
    padding: 0 50px;
    line-height: 45px;
}

.login-11 .btn-primary{
    background: #f78c30;
}

.login-11 .btn-primary:after {
    background: #e0781f;
}

.login-11 .btn-light:after {
    background: #f9fafb;
}

.login-11 .full-none{
    display: none;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-11 .full-none a{
    color: #535353;
}

.login-11 .social-list{
    display: inline-flex;
    margin-top: 35px;
}

.login-11 .social-list .icon {
    position: relative;
    border-radius: 100px;
    margin: 0 4px 3px 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #ffffff;
}

.login-11 .social-list .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-11 .social-list .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-11 .social-list .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.login-11 .social-list .icon:hover span,
.login-11 .social-list .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.login-11 .social-list .facebook{
    background: #3b5999;
}

.login-11 .social-list .facebook:hover .tooltip,
.login-11 .social-list .facebook:hover .tooltip::before {
    background: #3b5999;
}

.login-11 .social-list .twitter{
    background: #46c1f6;
}

.login-11 .social-list .twitter:hover .tooltip,
.login-11 .social-list .twitter:hover .tooltip::before {
    background: #46c1f6;
}

.login-11 .social-list .instagram{
    background: #db4437;
}

.login-11 .social-list .instagram:hover .tooltip,
.login-11 .social-list .instagram:hover .tooltip::before {
    background: #db4437;
}

.login-11 .social-list .github{
    background: #2392e0;
}

.login-11 .social-list .github:hover .tooltip,
.login-11 .social-list .github:hover .tooltip::before {
    background: #2392e0;
}

.login-11 .bg-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    /* background: url(../../assets/img/img-11.jpg); */
    background-size: cover;
    top: 0;
    left: 0;
    z-index: 0;
}

.login-11 .bg-img:before {
    content: "";
    background: #000;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0.3;
}

@keyframes sf-fly-by-1 {
    from {
        transform: translateZ(-600px);
        opacity: 0.5;
    }
    to {
        transform: translateZ(0);
        opacity: 0.5;
    }
}
@keyframes sf-fly-by-2 {
    from {
        transform: translateZ(-1200px);
        opacity: 0.5;
    }
    to {
        transform: translateZ(-600px);
        opacity: 0.5;
    }
}
@keyframes sf-fly-by-3 {
    from {
        transform: translateZ(-1800px);
        opacity: 0.5;
    }
    to {
        transform: translateZ(-1200px);
        opacity: 0.5;
    }
}

.login-11 .star-field {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    perspective: 600px;
    -webkit-perspective: 600px;
    z-index: -1;
}

.login-11 .star-field .layer {
    box-shadow: -411px -476px #cccccc, 777px -407px #d4d4d4, -387px -477px #fcfcfc, -91px -235px #d4d4d4, 491px -460px #f7f7f7, 892px -128px #f7f7f7, 758px -277px #ededed, 596px 378px #cccccc, 647px 423px whitesmoke, 183px 389px #c7c7c7,
    524px -237px #f0f0f0, 679px -535px #e3e3e3, 158px 399px #ededed, 157px 249px #ededed, 81px -450px #ebebeb, 719px -360px #c2c2c2, -499px 473px #e8e8e8, -158px -349px #d4d4d4, 870px -134px #cfcfcf, 446px 404px #c2c2c2,
    440px 490px #d4d4d4, 414px 507px #e6e6e6, -12px 246px #fcfcfc, -384px 369px #e3e3e3, 641px -413px #fcfcfc, 822px 516px #dbdbdb, 449px 132px #c2c2c2, 727px 146px #f7f7f7, -315px -488px #e6e6e6, 952px -70px #e3e3e3,
    -869px -29px #dbdbdb, 502px 80px #dedede, 764px 342px #e0e0e0, -150px -380px #dbdbdb, 654px -426px #e3e3e3, -325px -263px #c2c2c2, 755px -447px #c7c7c7, 729px -177px #c2c2c2, -682px -391px #e6e6e6, 554px -176px #ededed,
    -85px -428px #d9d9d9, 714px 55px #e8e8e8, 359px -285px #cfcfcf, -362px -508px #dedede, 468px -265px #fcfcfc, 74px -500px #c7c7c7, -514px 383px #dbdbdb, 730px -92px #cfcfcf, -112px 287px #c9c9c9, -853px 79px #d6d6d6,
    828px 475px #d6d6d6, -681px 13px #fafafa, -176px 209px #f0f0f0, 758px 457px #fafafa, -383px -454px #ededed, 813px 179px #d1d1d1, 608px 98px whitesmoke, -860px -65px #c4c4c4, -572px 272px #f7f7f7, 459px 533px #fcfcfc,
    624px -481px #e6e6e6, 790px 477px #dedede, 731px -403px #ededed, 70px -534px #cccccc, -23px 510px #cfcfcf, -652px -237px whitesmoke, -690px 367px #d1d1d1, 810px 536px #d1d1d1, 774px 293px #c9c9c9, -362px 97px #c2c2c2,
    563px 47px #dedede, 313px 475px #e0e0e0, 839px -491px #e3e3e3, -217px 377px #d4d4d4, -581px 239px #c2c2c2, -857px 72px #cccccc, -23px 340px #dedede, -837px 246px white, 170px -502px #cfcfcf, 822px -443px #e0e0e0, 795px 497px #e0e0e0,
    -814px -337px #cfcfcf, 206px -339px #f2f2f2, -779px 108px #e6e6e6, 808px 2px #d4d4d4, 665px 41px #d4d4d4, -564px 64px #cccccc, -380px 74px #cfcfcf, -369px -60px #f7f7f7, 47px -495px #e3e3e3, -383px 368px #f7f7f7, 419px 288px #d1d1d1,
    -598px -50px #c2c2c2, -833px 187px #c4c4c4, 378px 325px whitesmoke, -703px 375px #d6d6d6, 392px 520px #d9d9d9, -492px -60px #c4c4c4, 759px 288px #ebebeb, 98px -412px #c4c4c4, -911px -277px #c9c9c9;
    transform-style: preserve-3d;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 4px;
    width: 4px;
    border-radius: 2px;
}

.login-11 .star-field .layer:nth-child(1) {
    animation: sf-fly-by-1 5s linear infinite;
}

.login-11 .star-field .layer:nth-child(2) {
    animation: sf-fly-by-2 5s linear infinite;
}

.login-11 .star-field .layer:nth-child(3) {
    animation: sf-fly-by-3 5s linear infinite;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-11 .bg-img {
        display: none;
    }
}

@media (max-width: 768px) {
    .login-11 .form-section {
        padding: 50px 30px;
    }

    .login-11 .btn-section{
        display: none;
    }

    .login-11 .full-none{
        display: initial;
    }

    .login-11 .social-list {
        margin: 30px 0;
    }
}
/** Login 11 start **/

/** Login 12 start **/
.login-12 .login-inner-form {
    color: #cccccc;
    position: relative;
}

.login-12 .form-info {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
}


.login-12 .form-section {
    max-width: 430px;
    margin: 0 auto;
    text-align: center;
}

.login-12 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-12 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-12 .login-inner-form .form-control {
    font-size: 16px;
    outline: none;
    color: #535353;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid #dcdcdc;
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.login-12 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-12 .login-inner-form .form-box .form-control {
    float: left;
    width: 100%;
    padding: 12px 45px 12px 20px;
    border-radius: 50px;
}

.login-12 .login-inner-form .form-box i {
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: 18px;
    color: #535353;
}

.login-12 .login-inner-form .forgot {
    margin: 0;
    line-height: 45px;
    color: #535353;
    font-size: 16px;
    float: right;
}

.login-12 .bg-img {
    /* background: url(../../assets/img/img-12.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-12 .bg-img:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(36 10 99 / 52%);
}

.login-12 .info{
    max-width: 800px;
    margin: 0 auto;
    z-index: 999;
}

.login-12 .info h1 {
    font-size: 60px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.login-12 .info {
    text-align: left;
}

.login-12 .info p {
    margin-bottom: 0;
    color: #fff;
    opacity: 0.9;
    line-height: 28px;
}

.login-12 .login-inner-form p {
    margin: 0;
    color: #535353;
}

.login-12 .login-inner-form p a {
    color: #535353;
}

.login-12 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-12 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-12 .logo img {
    height: 30px;
    margin-bottom: 15px;
}

.login-12 .nav-pills li {
    display: inline-block;
}

.login-12 .login-inner-form .checkbox {
    margin-bottom: 25px;
    font-size: 14px;
}

.login-12 .login-inner-form .form-check {
    float: left;
    margin-bottom: 0;
}

.login-12 .login-inner-form .form-check a {
    color: #d6d6d6;
    float: right;
}

.login-12 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-12 .login-inner-form .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-12 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #535353;
}

.login-12 .form-section h3 {
    font-size: 23px;
    margin-bottom: 40px;
    font-weight: 400;
    color: #040404;
}

.login-12  .animated-text h1{
    display: block;
    text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
    /* background: url(../../assets/img/animated-text-2.png) repeat-y; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: aitf 80s linear infinite;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
}

@-webkit-keyframes aitf {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.login-12 h2 {
    color: #fff;
    margin: 0 0 25px;
    font-weight: 700;
    font-size: 50px;
}

.login-12 .form-section p {
    margin: 25px 0 0;
    font-size: 16px;
    color: #535353;
}

.login-12 .form-section p a {
    color: #535353;
}

.login-12 .form-check-input:focus {
    border-color: #ffbb19;
    outline: 0;
    box-shadow: none;
}

.login-12 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    position: absolute;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    margin-left: -20px;
}

.login-12 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.login-12 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-12 .form-section .social-list li a {
    font-size: 13px;
    width: 120px;
    margin: 2px 0 3px 0;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.login-12 .form-section .social-list li a i {
    height: 40px;
    width: 40px;
    line-height: 40px;
    float: left;
    color: #fff;
    border-radius: 20px;
    -webkit-transition: all 0.8s;
    transition: all 0.8s;
    font-size: 15px;
}

.login-12 .form-check-input:checked {
    background-color: #ff0000;
    border: 1px solid #ff0000;
}

.login-12 .form-section a {
    text-decoration: none;
}

.login-12 .form-section:hover .social-list li a i{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.login-12 .form-section .social-list li a span {
    margin-right: 7px;
}

.login-12 .form-section .extra-login {
    margin: 20px 0 25px;
    position: relative;
}

.login-12 .form-section .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #d8dcdc;
    content: "";
}

.login-12 .form-section .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    background: #fff;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-size: 13px;
    color: #535353;
    text-transform: capitalize;
}

.login-12 .btn-theme {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none;
    color: #fff;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Jost', sans-serif;
    border-radius: 50px;
    border: none;
}

.login-12 .btn-lg{
    padding: 0 50px;
    line-height: 50px;
}

.login-12 .btn{
    box-shadow: none!important;
}

.login-12 .btn-md{
    padding: 0 50px;
    line-height: 45px;
}

.login-12 .btn-primary{
    background: #ff0000;
}

.login-12 .btn-primary:after {
    background: #e90404;
}

.login-12 .btn-light:after {
    background: #f9fafb;
}

.login-12 .btn-theme i::before {
    position: relative;
    font-size: 18px;
    top: 3px;
    padding-left: 5px;
}

.login-12 .btn-theme:hover {
    color: #fff;
}

.login-12 .btn-theme:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.login-12 .btn-theme:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    border-radius: 7px;
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

.login-12 .facebook-i {
    background: #4867aa;
    color: #fff;
}

.login-12 .twitter-i {
    background: #33CCFF;
    color: #fff;
}

.login-12 .google-i {
    background: #db4437;
    color: #fff;
}

.login-12 .facebook-color {
    color: #4867aa;
}

.login-12 .twitter-color {
    color: #33CCFF;
}

.login-12 .google-color {
    color: #db4437;
}

.login-12 .lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -1;
}

.login-12 .line {
    position: absolute;
    width: 5px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.login-12 .line::after {
    content: '';
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    animation: drop 7s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    z-index: -888;
}

.login-12 .line:nth-child(1) {
    margin-left: -40%;
}

.login-12 .line:nth-child(1)::after {
    animation-delay: 1s;
}

.login-12 .line:nth-child(3) {
    margin-left: 40%;
}

.login-12 .line:nth-child(3)::after {
    animation-delay: 2s;
}

.login-12 .line:nth-child(4) {
    margin-left: -20%;
}

.login-12 .line:nth-child(4)::after {
    animation-delay: 3s;
}

.login-12 .line:nth-child(5) {
    margin-left: 20%;
}

.login-12 .line:nth-child(5)::after {
    animation-delay: 4s;
}

@keyframes drop {
    0% {top: -50%; }
    100% {top: 110%; }
}

/** MEDIA **/
@media (max-width: 1200px) {
    .login-12 .info h1 {
        font-size: 45px;
    }
}

@media (max-width: 992px) {
    .login-12 .bg-img {
        display: none;
    }

    .login-12 .form-info{
        padding: 30px 15px;
    }
}
/** Login 12 end **/

/** Login 13 start **/
.login-13 .form-info {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
}


.login-13 .form-section{
    max-width: 530px;
    margin: 0 auto;
    width: 100%;
}

.login-13 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-13 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-13 .login-inner-form .form-control {
    font-size: 16px;
    outline: none;
    color: #535353;
    border-radius: 3px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
    float: left;
    width: 100%;
    padding: 13px 40px 11px 15px;
}

.login-13 .login-inner-form .form-box i {
    position: absolute;
    top: 12px;
    right: 15px;
    font-size: 18px;
    color: #535353;
}

.login-13 .form-section a {
    text-decoration: none;
}

.login-13 .login-inner-form .forgot{
    margin: 0;
    line-height: 45px;
    color: #535353;
    font-size: 15px;
}

.login-13 .bg-img {
    top: 0;
    bottom: 0;
    min-height: 100vh;
    z-index: 999;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    overflow: hidden;
    background-image: linear-gradient(to bottom, #4b4b4b, #070707);
}

.bg-img-inner:before {
    content: "";
    width: 20%;
    height: 20%;
    position: absolute;
    top: 50px;
    left: 50px;
    border-radius: 100% 0 100% 100%;
    background-image: linear-gradient(to bottom, #585858, #000000);
}

.login-13 .bg-img-inner:after {
    content: "";
    width: 30%;
    height: 60%;
    position: absolute;
    top: 20%;
    right: 0;
    z-index: -1;
    -webkit-clip-path: polygon(0 0, 30% 0, 70% 10%);
    clip-path: polygon(0 0, 100% 50%, 100% 80%);
    background-image: linear-gradient(to bottom, #585858, #000000);
}

.login-13 .info{
    z-index: 999;
    max-width: 650px;
}

.login-13 .info p {
    margin-bottom: 0;
    color: #fff;
    line-height: 28px;
}

.login-13 .form-section .form-check .form-check-input {
    margin-left: -22px;
}

.login-13 .name_wrap h3 {
    position: relative;
    font-size: 55px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    color: #fff;
    line-height: 1.2;
    display: inline-block;
    overflow: hidden;
}

.login-13 .name_wrap h3 span {
    color: transparent;
    -webkit-text-stroke: 1px #fff;
    padding-left: 2px;
}


.login-13 .login-inner-form p{
    margin: 0;
    color: #535353;
}

.login-13 .login-inner-form p a{
    color: #535353;
}

.login-13 .logo img{
    height: 30px;
    margin-bottom: 20px;
}

.login-13 .nav-pills li{
    display: inline-block;
}

.login-13 .login-inner-form .form-group.mb-35{
    margin-bottom: 35px;
}

.login-13 .login-inner-form .form-group.mb-30{
    margin-bottom: 30px;
}

.login-13 .login-inner-form .terms{
    margin-left: 3px;
}

.login-13 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-13 .login-inner-form .form-check a {
    color: #d6d6d6;
    float: right;
}

.login-13 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-13 .login-inner-form .form-check-label {
    padding-left: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: #e5e5e5;
}

.login-13 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    position: absolute;
    border: 1px solid #fff;
    border-radius: 3px;
}

.login-13 .form-check-input:focus {
    border-color: #fff;
    outline: 0;
    box-shadow: none;
}

.login-13 .form-check-input:checked {
    background-color: #ff8a00;
    border-color: #ff8a00!important;
}

.login-13 .btn-section{
    top: 20px;
    position: absolute;
    left: 0px;
    float: right;
    display: inline-block;
    width: 100px;
}

.login-13 .btn-section .link-btn {
    font-size: 14px;
    float: left;
    background: transparent;
    font-weight: 400;
    line-height: 50px;
    width: 120px;
    text-decoration: none;
    text-decoration: blink;
    text-align: center;
    color: #fff!important;
    border-radius: 0 50px 50px 0;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.login-13 .btn-section .active-bg{
    background-image: linear-gradient(to bottom, #585858, #000000);
}

.login-13 .btn-section .default-bg{
    background-image: linear-gradient(to bottom, #ffb400, #ff8a00);
}

.login-13 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #e5e5e5;
    float: right;
    margin-left: 3px;
}

.login-13 .form-section{
    text-align: center;
    padding: 60px;
    border-radius: 5px;
    background-image: linear-gradient(to bottom, #585858, #000000);
}

.login-13 .form-section .form-section-innner:before{
    content: "";
    width: 20%;
    height: 20%;
    position: absolute;
    top: 50px;
    left: 50px;
    background-image: linear-gradient(to bottom, #ffb400, #ff8a00);
    border-radius: 100% 0 100% 100%;
    z-index: -999;
}

.login-13 .form-section .form-section-innner:after {
    content: "";
    width: 30%;
    height: 60%;
    position: absolute;
    top: 20%;
    right: 0;
    z-index: -1;
    -webkit-clip-path: polygon(0 0, 30% 0, 70% 10%);
    clip-path: polygon(0 0, 100% 50%, 100% 80%);
    background-image: linear-gradient(to bottom, #ffb400, #ff8a00);
}

.login-13 .form-section h3{
    font-size: 25px;
    margin-bottom: 25px;
    font-weight: 400;
    color: #fff;
}

.login-13 .form-section p {
    margin: 25px 0 0;
    font-size: 15px;
    color: #fff;
}

.login-13 .form-section p a {
    color: #fff;
}

.login-13 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.login-13 .form-section .social-list li {
    display: inline-block;
}

.login-13 .form-section .social-list li a {
    font-size: 13px;
    font-weight: 600;
    width: 125px;
    margin: 0 2px 5px 0;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.login-13 .form-section .social-list li a i {
    height: 40px;
    width: 40px;
    line-height: 40px;
    float: left;
    color: #fff;
}

.login-13 .none-2 {
    display: none;
}

.login-13 .btn-theme {
    color: #fff;
    text-align: center;
    border: 2px solid transparent;
    display: inline-block;
    padding: 0 50px;
    position: relative;
    line-height: 46px;
    z-index: 1;
    transition: all .7s ease;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    width: 100%;
}

.login-13 .btn-theme:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
    border-radius: 3px;
}

.login-13 .btn-theme:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
}

.login-13 .btn-theme:hover {
    background: transparent;
}

.login-13 .btn-theme:hover:before {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-13 .btn-theme:hover:after {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-13 .btn-lg{
    padding: 0 30px;
    line-height: 46px;
}

.login-13 .btn{
    box-shadow: none!important;
}

.login-13 .btn-md{
    padding: 0 45px;
    line-height: 41px;
}

.login-13 .btn-primary {
    background-image: linear-gradient(to bottom, #ffb400, #ff8a00);
}

.login-13 .btn-primary:before {
    background-image: linear-gradient(to bottom, #ffb400, #ff8a00);
}

.login-13 .btn-primary:after {
    background-image: linear-gradient(to bottom, #ffb400, #ff8a00);
}

.login-13 .btn-primary:hover {
    color: #ff8a00;
    border: 2px solid #ff8a00;
}

/** Social media **/
.login-13 .facebook-i {
    background: #4867aa;
    color: #fff;
}

.login-13 .twitter-i {
    background: #33CCFF;
    color: #fff;
}

.login-13 .google-i {
    background: #db4437;
    color: #fff;
}

.login-13 .facebook-color{
    color: #4867aa;
}

.login-13 .twitter-color {
    color: #33CCFF;
}

.login-13 .google-color {
    color: #db4437;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-13 .bg-img{
        display: none;
    }

    .login-13 .none-2 {
        display: block;
    }

    .login-13 .btn-section {
        display: none;
    }

    .login-13 .form-section .form-section-innner:before{
        display: none;
    }

    .login-13 .form-section .form-section-innner:after {
        display: none;
    }
}

@media (max-width: 768px) {
    .login-13 .form-section {
        padding: 50px 30px;
    }
}
/** Login 13 end **/

/** Login 14 start **/
.login-14 {
    background: linear-gradient(132deg, #FC415A, #591BC5, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    top: 0;
    text-align: center;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.login-14 .cube {
    position: absolute;
    top: 80vh;
    left: 45vw;
    width: 10px;
    height: 10px;
    border: solid 1px #D7D4E4;
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 12s ease-in forwards infinite;
}

.login-14 .cube:nth-child(2n) {
    border-color: #FFF ;
}

.login-14 .cube:nth-child(2) {
    animation-delay: 2s;
    left: 25vw;
    top: 40vh;
}

.login-14 .cube:nth-child(3) {
    animation-delay: 4s;
    left: 75vw;
    top: 50vh;
}

.login-14 .cube:nth-child(4) {
    animation-delay: 6s;
    left: 90vw;
    top: 10vh;
}

.login-14 .cube:nth-child(5) {
    animation-delay: 8s;
    left: 10vw;
    top: 85vh;
}

.login-14 .cube:nth-child(6) {
    animation-delay: 10s;
    left: 50vw;
    top: 10vh;
}

/* Animate Background*/
@keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@keyframes cube {
    from {
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        opacity: 1;
    }
    to {
        transform: scale(20) rotate(960deg) translate(-50%, -50%);
        opacity: 0;
    }
}


.login-14 .form-section {
    max-width: 500px;
    margin: 0 auto;
}

.login-14 .form-section .details{
    padding: 60px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.login-14 .form-section p{
    color: #f1f1f1;
    margin-bottom: 0;
    font-size: 16px;
}

.login-14 .form-section p a{
    color: #f1f1f1;
}

.login-14 .form-section ul{
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-14 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-14 .logo img{
    margin-bottom: 30px;
    height: 30px;
}

.login-14 .form-section .thembo{
    margin-left: 4px;
}

.login-14 .form-section h3 {
    margin: 0 0 25px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-14 .form-section .form-group {
    margin-bottom: 30px;
}

.login-14 .form-section .form-control {
    font-size: 16px;
    outline: none;
    background: transparent!important;
    color: #535353;
    font-weight: 500;
    border-radius: 0;
    padding: 12px 0;
    border: none;
    border-bottom: solid 2px #bdbdbd;
}

.login-14 .form-section .checkbox .terms{
    margin-left: 3px;
}

.login-14 .form-section button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-14 .form-section .terms{
    margin-left: 3px;
}

.login-14 .nav-pills li{
    display: inline-block;
}

.login-14 .form-section .form-check{
    margin-bottom: 0;
    text-align: left;
    padding-left: 20px;
}

.login-14 .form-section .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-14 .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    border: 2px solid #afabab;
}

.form-check-input:focus {
    outline: 0;
    box-shadow: none;
}

.login-14 .form-check-input:checked {
    background-color: #bf06e0;
    border-color: #bf06e0!important;
}

.login-14 .form-section a {
    text-decoration: none;
}

.login-14 .btn-theme {
    position: relative;
    display: inline-block;
    width: 100%;
    color: #ffffff;
    border: none;
    overflow: hidden;
    overflow: hidden;
    text-transform: capitalize;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
}

.login-14 .btn-theme:hover {
    color: #fff;
}

.login-14 .btn-theme:hover::before {
    left: 0%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.login-14 .btn-theme:before {
    position: absolute;
    content: '';
    left: 96%;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    transform: skewX(-25deg);
}

.login-14 .btn-theme span {
    position: relative;
    z-index: 1;
}

.login-14 .btn-lg{
    padding: 0 50px;
    line-height: 50px;
}

.login-14 .btn{
    box-shadow: none!important;
}

.login-14 .btn-md{
    padding: 0 45px;
    line-height: 50px;
}

.login-14 .btn-primary {
    background: #bf06e0;
}

.login-14 .btn-primary:before {
    background: #d31ef3;
}

.login-14 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
}

.login-14 .social-list{
    float: left;
}

.login-14 .social-list span {
    margin-right: 5px;
    font-size: 15px;
    color: #535353;
}

.login-14 .social-list a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    color: rgb(255, 255, 255);
    margin: 0 2px 2px 0;
    border-radius: 5%;
}

/** Social media **/
.login-14 .facebook-bg{
    background: #4867aa;
}

.login-14 .facebook-bg:hover {
    background: #3b589e;
}

.login-14 .twitter-bg {
    background: #33CCFF;
}

.login-14 .twitter-bg:hover {
    background: #2abdef;
}

.login-14 .google-bg {
    background: #db4437;
}

.login-14 .google-bg:hover {
    background: #cc4437;
}

.login-14 .linkedin-bg {
    background: #2392e0;
}

.login-14 .linkedin-bg:hover {
    background: #1c82ca;
    color: #fff;
}

@media (max-width: 768px) {
    .login-14 .form-section .details{
        padding: 60px 30px;
    }
}
/** Login 14 end **/

/** Login 15 start **/
.login-15 {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
    background: linear-gradient(to bottom right, #0300b7e8 0%, #ffffff 100%);
}


.login-15 .login-box {
    background: #fff;
    border-radius: 20px;
    margin: 0 auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    /* background: url(../../assets/img/img-15.jpg) top left repeat; */
    background-size: cover;
    max-width: 1140px;
    position: relative;
}

.login-15 .form-section {
    text-align: center;
    padding: 60px 70px;
    background: #fff;
    border-radius: 10px 0 0 10px;
    z-index: 999;
}

.login-15 .pad-0{
    padding: 0;
    z-index: 999;
}

.login-15 .info{
    padding: 30px;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    transform: scale(1);
    transition: 1s;
}

.login-15 .login-box:hover .info {
    transform: scale(1.1);
    z-index: 999;
}

.login-15 .info h3{
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 500;
    color: #fff;
}

.login-15 .form-section p{
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-15 .form-section p a{
    color: #535353;
}

.login-15 .form-section ul{
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-15 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-15 .logo-2 img{
    margin-bottom: 20px;
    height: 30px;
}

.login-15 .form-section .thembo{
    margin-left: 4px;
}

.login-15 .form-section h3 {
    margin: 0 0 25px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-15 .form-section .form-group {
    margin-bottom: 25px;
}

.login-15 .form-section .form-control {
    padding: 10px 25px;
    font-size: 16px;
    outline: none;
    height: 55px;
    color: #535353;
    border-radius: 0;
    border: 1px solid transparent;
    background: #f5f5f5;
}

.login-15 .form-section .checkbox .terms{
    margin-left: 3px;
}

.login-15 .form-section .terms{
    margin-left: 3px;
}

.login-15 .btn-section {
    margin-bottom: 25px;
    display: inline-block;
}

.login-15 .btn-section .btn-1 {
    border-radius: 0;
    border-right: solid 1px #e6e6e6;
}

.login-15 .btn-section .link-btn {
    font-size: 15px;
    float: left;
    background: #f5f5f5;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    text-decoration: blink;
    line-height: 40px;
    width: 110px;
    color: #535353;
    font-family: 'Jost', sans-serif;
    text-transform: uppercase;
}

.login-15 .form-section a {
    text-decoration: none;
}

.login-15 .btn-section .link-btn:hover{
    color: #0060ff;
}

.login-15 .btn-section .active-bg {
    color: #0060ff;
}

.login-15 .btn-section .btn-2 {
    border-radius: 0;
}

.login-15 .form-section .form-check{
    float: left;
    margin-bottom: 0;
}

.login-15 .form-section .form-check a {
    color: #535353;
}

.login-15 .form-section .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-15 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
    float: right;
    line-height: 50px;
}

.login-15 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: -22px;
    margin-top: 0;
    border-radius: 3px;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
}

.login-15 .form-check-input:checked {
    background-color: #0060ff;
}

.login-15 .form-check-input:focus {
    border-color: transparent;
    box-shadow: none;
}

.login-15 .social-list a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    display: inline-block;
    font-size: 19px;
    margin: 2px;
    border-radius: 5%;
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.login-15 .social-list a:hover{
    color: #fff;
}

.login-15 .form-section .btn-theme {
    position: relative;
    display: inline-block;
    float: left;
    color: #ffffff;
    overflow: hidden;
    overflow: hidden;
    text-transform: capitalize;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
}

.login-15 .form-section .btn-theme:hover {
    color: #fff;
}

.login-15 .form-section .btn-theme:hover::before {
    left: 0%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.login-15 .form-section .btn-theme:before {
    position: absolute;
    content: '';
    left: 90%;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    transform: skewX(-25deg);
}

.login-15 .form-section .btn-theme span {
    position: relative;
    z-index: 1;
}

.login-15 .form-section .btn-theme {
    border: none;
}

.login-15 .form-section .btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: none;
}

.login-15 .form-section .logo{
    display: none;
}

.login-15 .btn-lg{
    padding: 0 50px;
    line-height: 55px;
}

.login-15 .btn{
    box-shadow: none!important;
}

.login-15 .btn-md{
    padding: 0 45px;
    line-height: 50px;
}

.login-15 .btn-primary {
    background: #0060ff;
}

.login-15 .btn-primary:before {
    background: #0254dc;
}

/** Social media **/
.login-15 .facebook-bg{
    color: #4867aa;
}

.login-15 .facebook-bg:hover {
    background: #4867aa;
}

.login-15 .twitter-bg {
    color: #33CCFF;
}

.login-15 .twitter-bg:hover {
    background: #33CCFF;
}

.login-15 .google-bg {
    color: #db4437;
}

.login-15 .google-bg:hover {
    background: #db4437;
}

.login-15 .linkedin-bg {
    color: #2392e0;
}

.login-15 .linkedin-bg:hover {
    background: #1c82ca;
}

.login-15 .animation-container .lightning-container {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    transform: translateY(-50%);
}

.login-15 .animation-container .lightning-container .lightning {
    position: absolute;
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    transform-origin: 6px 6px;
    animation-name: woosh;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    animation-direction: alternate;
}

.login-15 .animation-container .lightning-container .lightning.white {
    background-color: white;
    box-shadow: 0px 50px 50px 0px rgba(255, 255, 255, 0.3);
}

.login-15 .animation-container .lightning-container .lightning.red {
    background-color: #fc7171;
    box-shadow: 0px 50px 50px 0px rgba(252, 113, 113, 0.3);
    animation-delay: 0.2s;
}

.login-15 .animation-container .boom-container {
    position: absolute;
    display: flex;
    width: 80px;
    height: 80px;
    text-align: center;
    align-items: center;
    transform: translateY(-50%);
    left: 200px;
    top: -145px;
}

.login-15 .animation-container .boom-container .shape {
    display: inline-block;
    position: relative;
    opacity: 0;
    transform-origin: center center;
}

.login-15 .animation-container .boom-container .shape.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    transform-origin: 50% 80%;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    margin-left: -15px;
    border-width: 0 2.5px 5px 2.5px;
    border-color: transparent transparent #42e599 transparent;
    animation-name: boom-triangle;
}

.login-15 .animation-container .boom-container .shape.triangle.big {
    margin-left: -25px;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent #fade28 transparent;
    animation-name: boom-triangle-big;
}

.login-15 .animation-container .boom-container .shape.disc {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #d15ff4;
    animation-name: boom-disc;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}

.login-15 .animation-container .boom-container .shape.circle {
    width: 20px;
    height: 20px;
    animation-name: boom-circle;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    border-radius: 100%;
    margin-left: -30px;
}

.login-15 .animation-container .boom-container .shape.circle.white {
    border: 1px solid white;
}

.login-15 .animation-container .boom-container .shape.circle.big {
    width: 40px;
    height: 40px;
    margin-left: 0px;
}

.login-15 .animation-container .boom-container .shape.circle.big.white {
    border: 2px solid white;
}

.login-15 .animation-container .boom-container .shape:after {
    background-color: rgba(178, 215, 232, 0.2);
}

.login-15 .animation-container .boom-container .shape.triangle, .animation-container .boom-container .shape.circle, .animation-container .boom-container .shape.circle.big, .animation-container .boom-container .shape.disc {
    animation-delay: 0.38s;
    animation-duration: 3s;
}

.login-15 .animation-container .boom-container .shape.circle {
    animation-delay: 0.6s;
}

.login-15 .animation-container .boom-container.second {
    left: 485px;
    top: 155px;
}

.login-15 .animation-container .boom-container.second .shape.triangle, .animation-container .boom-container.second .shape.circle, .animation-container .boom-container.second .shape.circle.big, .animation-container .boom-container.second .shape.disc {
    animation-delay: 1.9s;
}

.login-15 .animation-container .boom-container.second .shape.circle {
    animation-delay: 2.15s;
}

@keyframes woosh {
    0% {
        width: 12px;
        transform: translate(0px, 0px) rotate(-35deg);
    }
    15% {
        width: 50px;
    }
    30% {
        width: 12px;
        transform: translate(214px, -150px) rotate(-35deg);
    }
    30.1% {
        transform: translate(214px, -150px) rotate(46deg);
    }
    50% {
        width: 110px;
    }
    70% {
        width: 12px;
        transform: translate(500px, 150px) rotate(46deg);
    }
    70.1% {
        transform: translate(500px, 150px) rotate(-37deg);
    }
    85% {
        width: 50px;
    }
    100% {
        width: 12px;
        transform: translate(700px, 0) rotate(-37deg);
    }
}
@keyframes boom-circle {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    30% {
        opacity: 0;
        transform: scale(3);
    }
}
@keyframes boom-triangle-big {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    40% {
        opacity: 0;
        transform: scale(2.5) translate(50px, -50px) rotate(360deg);
    }
}
@keyframes boom-triangle {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    30% {
        opacity: 0;
        transform: scale(3) translate(20px, 40px) rotate(360deg);
    }
}
@keyframes boom-disc {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    40% {
        opacity: 0;
        transform: scale(2) translate(-70px, -30px);
    }
}

@media (max-width: 992px) {
    .login-15 .form-section {
        border-radius: 10px;
    }

    .login-15 .bg-img{
        display: none;
    }

    .login-15 .login-box {
        max-width: 500px;
    }

    .login-15 .form-section .logo{
        display: inherit;
    }

    .login-15 .form-section .logo img{
        height: 30px;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .login-15 .form-section{
        padding: 50px 30px;
    }

    .login-15 .animation-container{
        display: none;
    }
}
/** Login 15 end **/

/** Login 16 start **/
.login-16 {
    /* background: url(../../assets/img/img-7.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    width: 100%;
    text-align: center;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    background: #fff;
    overflow: hidden;
}

.login-16 .ocean {
    height: 3%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #015871;
}

.login-16 .wave {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
}

.login-16 .wave:nth-of-type(2) {
    top: -175px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1;
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}

@keyframes swell {
    0%, 100% {
        transform: translate3d(0, -25px, 0);
    }
    50% {
        transform: translate3d(0, 5px, 0);
    }
}

.login-16 .photo{
    padding: 0 15px;
    max-width: 500px;
    margin: auto;
}

.login-16 .form-section {
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
}


.login-16 a {
    text-decoration: none;
}

.login-16 .form-inner {
    max-width: 600px;
    width: 100%;
    padding: 70px;
    text-align: center;
    z-index: 999;
    background: #fff;
    box-shadow: 0 0 35px rgb(0 0 0 / 10%);
}

.login-16 .bg-img {
    display: flex;
}

.login-16 .form-section .extra-login {
    float: left;
    width: 100%;
    margin: 25px 0 25px;
    text-align: center;
    position: relative;
}

.login-16 .form-section .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #d8dcdc;
    content: "";
}

.login-16 .form-section .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-size: 14px;
    color: #535353;
    text-transform: capitalize;
    background: #fff;
}

.login-16 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    vertical-align: top;
    position: absolute;
    border-radius: 2px;
    border: none;
    background-color: #f7f7f7;
    margin-left: -22px;
}

.login-16 .form-check-input:focus {
    border-color: snow;
    outline: 0;
    box-shadow: none;
}

.login-16 .form-check-input:checked {
    background-color: #2ad4bc;
}

.login-16 .form-section p {
    color: #535353;
    margin-bottom: 0;
    font-size: 16px;
}

.login-16 .form-section p a {
    color: #535353;
}

.login-16 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-16 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-16 .form-section .thembo {
    margin-left: 4px;
}

.login-16 .form-section h3 {
    margin: 0 0 25px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-16 .form-section .form-group {
    width: 100%;
    position: relative;
    margin-bottom: 25px;
}

.login-16 .form-section .form-control {
    padding: 11px 20px 9px;
    font-size: 16px;
    outline: none;
    height: 50px;
    color: #535353;
    border-radius: 3px;
    border: 1px solid transparent;
    background: #f7f7f7;
}

.login-16 .form-section .form-check {
    margin-bottom: 0;
}

.login-16 .form-section .form-check a {
    color: #535353;
}

.login-16 .form-section .form-check-label {
    padding-left: 5px;
    font-size: 16px;
    color: #535353;
}

.login-16 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
}

.login-16 .logo img {
    margin-bottom: 15px;
    height: 25px;
}

.login-16 .btn-theme {
    position: relative;
    display: inline-block;
    border: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    width: 100%;
}

.login-16 .btn-theme:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    border-radius: 3px;
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

.login-16 .btn-theme:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.login-16 .btn-lg{
    padding: 0 50px;
    line-height: 50px;
}

.login-16 .btn{
    box-shadow: none!important;
}

.login-16 .btn-md{
    padding: 0 50px;
    line-height: 45px;
}

.login-16 .btn-primary{
    background: #2ad4bc;
    border-color: #51d4bc;
}

.login-16 .btn-primary:after {
    background: #1abfa8;
}

.login-16 .social-list {
    display: inline-flex;
    margin-bottom: 27px;
}

.login-16 .social-list .icon {
    position: relative;
    background: #f3f3f3;
    border-radius: 3px;
    margin: 0 4px 3px 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #ffffff;
}

.login-16 .social-list .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-16 .social-list .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-16 .social-list .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.login-16 .social-list .icon:hover span,
.login-16 .social-list .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.login-16 .social-list .facebook{
    background: #3b5999;
}

.login-16 .social-list .facebook:hover .tooltip,
.login-16 .social-list .facebook:hover .tooltip::before {
    background: #3b5999;
}

.login-16 .social-list .twitter{
    background: #46c1f6;
}

.login-16 .social-list .twitter:hover .tooltip,
.login-16 .social-list .twitter:hover .tooltip::before {
    background: #46c1f6;
}

.login-16 .social-list .instagram{
    background: #db4437;
}

.login-16 .social-list .instagram:hover .tooltip,
.login-16 .social-list .instagram:hover .tooltip::before {
    background: #db4437;
}

.login-16 .social-list .github{
    background: #2392e0;
}

.login-16 .social-list .github:hover .tooltip,
.login-16 .social-list .github:hover .tooltip::before {
    background: #2392e0;
}

/** Social media **/
@media (max-width: 992px) {
    .login-16 .form-inner {
        padding: 50px;
    }

    .login-16 .bg-img{
        display: none;
    }
}

@media (max-width: 768px) {
    .login-16 .form-inner {
        padding: 50px 30px;
    }
}
/** Login 16 end **/

/** Login 17 start **/
.login-17 .bg-img{
    top: 0;
    bottom: 0;
    min-height: 100vh;
    z-index: 999;
    opacity: 1;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 30px 100px;
    background: linear-gradient(132deg, #FC415A, #591BC5, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    overflow: hidden;
}

.login-17 a {
    text-decoration: none;
}

.login-17 .form-check-input:checked {
    display: none;
}


.login-17 .form-section{
    top: 0;
    bottom: 0;
    opacity: 1;
    min-height: 100vh;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 60px;
}

.login-inner-form{
    width: 100%;
}

.login-17 .login-inner-form .forgot{
    line-height: 50px;
    float: right;
    color: #535353;
    font-size: 16px;
}

.login-17 .login-inner-form p{
    color: #535353;
    margin-bottom: 0;
    font-size: 16px;
}

.login-17 .login-inner-form p a{
    color: #535353;
}

.login-17 .login-inner-form .thembo{
    margin-left: 4px;
}

.login-17 .login-inner-form h3 {
    margin: 0 0 50px;
    font-size: 30px;
    font-weight: 400;
    color: #040404;
}

.login-17 .waviy {
    position: relative;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 30px;
}

.login-17 .waviy span {
    position: relative;
    display: inline-block;
    color: #fff;
    animation: waviy 1s infinite;
    animation-delay: calc(.1s * var(--i));
    font-family: 'Jost', sans-serif;
}

.login-17 .waviy span.color-yellow{
    color: #33ccff;
}

@keyframes waviy {
    0%,40%,100% {
        transform: translateY(0)
    }
    20% {
        transform: translateY(-20px)
    }
}

.login-17 .login-inner-form .form-group {
    margin-bottom: 40px;
}

.login-17 .login-inner-form .form-control {
    width: 100%;
    padding: 0 0 10px;
    font-size: 16px;
    font-weight: 400;
    background: transparent;
    border: none;
    border-bottom: 2px solid #e8e7e7;
    outline: none;
    color: #535353;
    border-radius: 0;
}

.login-17 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 13px 50px 12px 50px;
    font-size: 17px;
    font-weight: 400;
    height: 50px;
}

.login-17 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-17 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-17 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-17 .login-inner-form .btn-theme {
    background: #33ccff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border: none;
    color: #fff;
    border-radius: 50px;
}

.login-17 .login-inner-form .btn-theme:hover {
    background: #29c2f5;
}


.login-17 .btn-theme {
    border-radius: 50px;
    padding: 12px 50px 11px 50px;
    color: #33ccff;
    border: solid 2px #33ccff;
    font-size: 17px;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    float: left;
    margin-right: 5px;
}

.login-17 .btn-theme:hover{
    color: #fff;
    background: #33ccff;
    text-decoration: none;
}

.login-17 .informeson{
    max-width: 665px;
}

.login-17 .informeson p{
    margin-bottom: 40px;
    font-size: 17px;
    color: #ebebeb;
}

.login-17 .social-box ul{
    margin: 0;
    padding: 0;
}

.login-17 .social-box .social-list li {
    font-size: 16px;
    display: inline-block;
}

.login-17 .social-box .social-list li a {
    margin: 0 20px 2px 0;
    font-size: 15px;
    display: inline-block;
    color: #ebebeb;
    text-decoration: none;
    text-transform: uppercase;
}

.login-17 .social-box .social-list li a:hover{
    color: #fff;
}

.login-17 .social-box{
    bottom: 30px;
    position: absolute;
    left: 100px;
}

.login-17 .logo{
    top: 30px;
    position: absolute;
    left: 100px;
}

.login-17 .logo img{
    height: 30px;
}

.login-17 .logo-2 {
    display: none;
    margin-bottom: 15px;
}

.login-17 .logo-2 img{
    height: 30px;
}

.login-17 .none-2{
    display: none;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-17 .bg-img{
        display: none;
    }

    .login-17 .none-2{
        display: inherit;
    }

    .login-17 .logo-2 {
        display: inherit;
    }

    .login-17 .login-inner-form h3 {
        font-size: 25px;
        margin: 0 0 40px;
    }

    .login-17 .login-inner-form{
        max-width: 450px;
        padding: 60px 30px;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .login-17 .form-section {
        padding: 30px 15px;
    }

    .login-17{
        background: #fff3f3;
    }
}
/** Login 17 end **/

/** Login 18 start **/
.login-18 {
    /* background: url(../../assets/img/img-18.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    width: 100%;
    text-align: center;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.login-18:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #191fcb;
    content: "";
    z-index: -1;
    opacity: 0.4;
}

.login-18 a {
    text-decoration: none;
}


.login-18 .form-section {
    max-width: 550px;
    margin: 0 auto;
    padding: 70px 50px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 0;
}

.login-18 .form-section:before {
    content: "";
    width: 196px;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    /* background: url(../../assets/img/img-39.png) top left repeat; */
    background-size: cover;
    z-index: -1;
}

.login-18 .form-section:after {
    content: "";
    width: 196px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    /* background: url(../../assets/img/img-40.png) top left repeat; */
    background-size: cover;
    z-index: -1;
}

.login-18 .form-check-input:checked {
    display: none;
}

.login-18 .form-section p{
    color: #535353;
    margin-bottom: 0;
    text-align: center;
    font-size: 16px;
}

.login-18 .form-section p a{
    color: #535353;
}

.login-18 .form-section .extra-login {
    float: left;
    width: 100%;
    margin: 25px 0 25px;
    text-align: center;
    position: relative;
}

.login-18 .form-section .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #d8dcdc;
    content: "";
}

.login-18 .form-section .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    background: #fff;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-family: Open Sans;
    font-size: 15px;
    color: #535353;
    text-transform: capitalize;
}

.login-18 .form-section ul{
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-18 .logo-2 img{
    margin-bottom: 20px;
    height: 30px;
}

.login-18 .form-section .social-list li {
    display: inline-block!important;
    margin-bottom: 5px;
}

.login-18 .form-section .social-list li a {
    font-size: 14px;
    font-weight: 400;
    width: 130px;
    margin: 2px 0 3px 0;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-family: 'Jost', sans-serif;
}

.login-18 .form-section .social-list li a i{
    height: 40px;
    width: 40px;
    line-height: 40px;
    float: left;
    color: #fff;
    border-radius: 20px;
}

.login-18 .form-section .social-list li a span{
    margin-right: 7px;
}

.login-18 .form-section .thembo{
    margin-left: 4px;
}

.login-18 .form-section h3 {
    margin: 0 0 30px;
    font-size: 20px;
    font-weight: 500;
    color: #040404;
    text-transform: uppercase;
}

.login-18 .form-section .form-group {
    margin-bottom: 25px;
}

.login-18 .form-section .form-control {
    font-size: 16px;
    outline: none;
    background: #efefef;
    padding: 12px 25px;
    color: #535353;
    height: 60px;
    border-radius: 3px;
    border: 1px solid #efefef;
}

.login-18 .form-section .checkbox .terms{
    margin-left: 3px;
}

.login-18 .form-section .btn-md {
    cursor: pointer;
    height: 60px;
    color: #fff;
    padding: 13px 50px 12px 50px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
}

.login-18 .form-section input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-18 .form-section button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-18 .form-section .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-18 .form-section .btn-theme {
    background: #e91f23;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border: none;
    color: #fff;
}

.login-18 .form-section .btn-theme:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background: #db1519;
}

.login-18 .form-section .terms{
    margin-left: 3px;
}

.login-18 .form-section .form-check{
    float: left;
    margin-bottom: 0;
    padding-left: 0;
    position: relative;
}

.login-18 .form-section .form-check a {
    color: #535353;
}

.login-18 .form-section .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-18 .form-section .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 2px;
    margin-left: -25px;
    border: 1px solid #e8e8e8;
    background: #e8e8e8;
    border-radius: 3px;
}

.login-18 .form-section .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-18 .form-section .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #e91f23;
    border-color: #e91f23;
}

.login-18 .form-section input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #fff;
    line-height: 16px;
    font-size: 14px;
    content: "\2713";
    padding-left: 0;
}

.login-18 .form-section input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-18 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
    float: right;
    line-height: 60px;
}

/** Social media **/
.login-18 .facebook-i {
    background: #4867aa;
    color: #fff;
}

.login-18 .twitter-i {
    background: #33CCFF;
    color: #fff;
}

.login-18 .google-i {
    background: #db4437;
    color: #fff;
}

.login-18 .facebook-color{
    color: #4867aa;
}

.login-18 .twitter-color {
    color: #33CCFF;
}

.login-18 .google-color {
    color: #db4437;
}

@media (max-width: 768px) {
    .login-18 .form-section {
        padding: 50px 30px;
    }
}

@media (max-width: 500px) {
    .login-18 .form-section .social-list li a i {
        display: none;
    }

    .login-18 .form-section .social-list li a {
        width: 100px;
    }
}
/** Login 18 end **/

/** Login 19 start **/
.login-19 {
    background: #fff;
}

.login-19 a {
    text-decoration: none;
}


.login-19 .form-check-input:checked {
    display: none;
}

.login-19 .form-section {
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
    border-radius: 0 100% 100% 0;
    background: #fff;
    background: linear-gradient(132deg, #FC415A, #591BC5, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
}

.login-19 .form-inner {
    max-width: 450px;
    width: 100%;
    margin: 0 30px;
    text-align: center;
}

.login-19 .form-text {
    top: 0;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 50px;
}

.login-19 .form-section .extra-login span{
    color: #ededed;
}

.login-19 .info h1 {
    margin-bottom: 20px;
    font-size: 60px;
    font-weight: 700;
}

.login-19 .animate-charcter {
    background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 190px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.login-19 .form-section p {
    color: #ededed;
    margin-bottom: 0;
    font-size: 16px;
    text-align: center;
}

.login-19 .form-section p a {
    color: #ededed;
}

.login-19 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-19 .form-section .thembo {
    margin-left: 4px;
}

.login-19 .form-section h3 {
    margin: 0 0 30px;
    font-size: 25px;
    font-weight: 400;
    color: #fff;
}

.login-19 .form-section .form-group {
    margin-bottom: 25px;
}

.login-19 .form-section .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-19 .form-section .form-control {
    padding: 15.5px 20px;
    font-size: 16px;
    outline: none;
    color: #535353;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid transparent;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.login-19 .form-section img {
    margin-bottom: 5px;
    height: 35px;
}

.login-19 .form-section .form-box i {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 23px;
    color: #535353;
}

.login-19 .form-section .checkbox .terms {
    margin-left: 3px;
}

.login-19 .form-section .btn-md {
    cursor: pointer;
    padding: 15.5px 50px 14.5px 50px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 50px;
    height: 55px;
}

.login-19 .form-section input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-19 .form-section button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-19 .form-section .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-19 .form-section .btn-theme {
    background: #ff574d;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border: none;
    color: #fff;
    border-radius: 3px;
    font-weight: 400;
}

.login-19 .form-section .btn-theme:hover {
    background: #ef4b22;
}

.login-19 .form-section .terms {
    margin-left: 3px;
}

.login-19 .form-section .form-check {
    float: left;
    margin-bottom: 0;
    padding-left: 0;
}

.login-19 .form-section .form-check a {
    color: #535353;
    float: right;
}

.login-19 .form-section .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-19 .form-section .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 2px;
    margin-left: -25px;
    border: 1px solid #fff;
    border-radius: 3px;
    background-color: #fff;
}

.login-19 .form-section .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 16px;
    color: #ededed;
}

.login-19 .form-section .form-check-label a{
    color: #ededed;
}

.login-19 .form-section .checkbox-theme input[type="checkbox"]:checked + label::before {
    background: #ff574d;
    border-color: #ff574d;
}

.login-19 .form-section input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #fff;
    line-height: 16px;
    font-size: 12px;
    padding-left: 1px;
    content: "\2713";
}

.login-19 .form-section input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-19 .form-section a.forgot-password {
    font-size: 16px;
    color: #ededed;
    float: right;
}

.login-19 .logo img {
    margin-bottom: 15px;
    height: 30px;
}

.login-19 .info {
    max-width: 650px;
    padding: 10px 20px;
}

.login-19 .info p {
    color: #535353;
    line-height: 28px;
}

.login-19 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-19 .social-list {
    padding: 0;
    text-align: center;
}

.login-19 .social-list li {
    display: inline-block;
}

.login-19 .social-list li a {
    margin: 1px;
    font-size: 14px;
    font-weight: 500;
    width: 110px;
    height: 40px;
    line-height: 40px;
    border-radius: 0;
    display: inline-block;
    text-align: center;
    color: #fff;
}

.login-19 .social-list li a:hover {
    text-decoration: none;
}

.login-19 .facebook-bg {
    background: #4867aa;
}

.login-19 .facebook-bg:hover {
    background: #3d5996;
    color: #fff;
}

.login-19 .twitter-bg {
    background: #33CCFF;
}

.login-19 .twitter-bg:hover {
    background: #56d7fe;
}

.login-19 .google-bg {
    background: #db4437;
}

.login-19 .google-bg:hover {
    background: #dc4e41;
}

@media (max-width: 992px) {
    .login-19 .form-section {
        border-radius: 0;
        padding: 30px 0;
    }

    .login-19 .form-text {
        display: none;
    }
}

@media (max-width: 768px) {
    .login-19 .form-inner {
        margin: 0 15px;
    }
}
/** Login 19 end **/

/** Login 20 start **/
.login-20 .login-20-inner {
    z-index: 999;
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 30px 0;
}


.login-20 .login-box {
    background: #fff;
    margin: 0 auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    position: relative;
    max-width: 1120px;
    z-index: 0;
}

.login-20 .login-box:before {
    content: "";
    width: 50%;
    margin-left: auto;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    /* background: url(../../assets/img/img-65.jpg) top left repeat; */
    background-size: cover;
    z-index: -1;
}

.login-20 .form-section {
    text-align: center;
    padding: 80px 90px;
    background: #fff;
    border-right: solid 1px #e9dede;
}

.login-20 .pad-0{
    padding: 0;
}

.login-20 .form-section p{
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-20 .form-section p a{
    color: #535353;
}

.login-20 .form-section ul{
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-20 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-20 .form-section .thembo{
    margin-left: 4px;
}

.login-20 .logo img{
    height: 30px;
    margin-bottom: 20px;
}

.login-20 .form-section h3 {
    text-align: center;
    margin: 0 0 25px;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: #040404;
}

.login-20 .form-section .form-group {
    margin-bottom: 25px;
}

.login-20 .form-section .form-group .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-20 .form-section .form-group  .form-box .form-control {
    padding: 15px 15px 15px 50px;
}

.login-20 .form-section .form-group .form-box i {
    position: absolute;
    top: 14px;
    left: 20px;
    font-size: 20px;
    color: #777575;
}

.login-20 .form-section .checkbox .terms{
    margin-left: 3px;
}

.login-20 .form-section button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-20 .form-section .terms{
    margin-left: 3px;
}

.login-20 .form-section a {
    text-decoration: none;
}

.login-20 .form-section .checkbox {
    font-size: 15px;
}

.login-20 .form-section .form-check{
    float: left;
    margin-bottom: 0;
}

.login-20 .form-section .form-check a {
    color: #535353;
}

.login-20 .form-section .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-20 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
}

.login-20 .form-check-input:focus {
    border-color: transparent;
    box-shadow: none;
}

.login-20 .form-check-input:checked {
    background-color: #4e74fe!important;
    border: 1px solid #4e74fe!important;
}

.login-20 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    border: 1px solid #ced4da;
    background-color: #fff;
    position: absolute;
    margin-left: -22px;
}

.login-20 .social-list a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    display: inline-block;
    font-size: 19px;
    background: #fff;
}

.login-20 .social-list a:hover{
    color: #fff;
}

.login-20 .btn-theme {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    float: left;
}

.login-20 .btn-theme:hover {
    color: #fff;
}

.login-20 .btn-theme:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.login-20 .btn-theme:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    border-radius: 7px;
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

.login-20 .btn-theme{
    background: #4e74fe;
}

.login-20 .btn-theme:after {
    background: #4167f3;
}

.login-20 .btn-lg{
    font-size: 17px;
    padding: 0 50px;
    line-height: 56px;
}

.login-20 .btn{
    box-shadow: none!important;
}

.login-20 .btn-md{
    padding: 0 50px;
    font-size: 15px;
    line-height: 45px;
}

.login-20 .info{
    position: relative;
    width: 350px;
    margin: 0 auto;
    height: 350px;
    text-align: center;
}

.login-20 .info .box h3{
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 500;
    color: #fff;
}

.login-20 .info .box {
    position: absolute;
    width: 350px;
    margin: 0 auto;
    height: 350px;
    overflow: hidden;
    padding: 20px;
    border: solid 15px #ffffff0f;
    background: #fff;
    /* background: url(../../assets/img/img-22.jpg) top left repeat; */
    background-size: cover;
}

.login-20 .info .box .content{
    position:absolute;
    top:15px;
    left:15px;
    right:15px;
    bottom:15px;
    border: solid 1px rgb(255 255 255 / 20%);
    padding: 99px 10px;
}

.login-20 .info .box span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
}

.login-20 .info .box span:nth-child(1) {
    transform:rotate(0deg);
}

.login-20 .info .box span:nth-child(2) {
    transform:rotate(90deg);
}

.login-20 .info .box span:nth-child(3) {
    transform:rotate(180deg);
}

.login-20 .info .box span:nth-child(4) {
    transform:rotate(270deg);
}

.login-20 .info .box span:before {
    content: '';
    position: absolute;
    width:100%;
    height: 3px;
    background: #fff;
    animation: animate 4s linear infinite;
}

@keyframes animate {
    0% {
        transform:scaleX(0);
        transform-origin: left;
    }
    50% {
        transform:scaleX(1);
        transform-origin: left;
    }
    50.1% {
        transform:scaleX(1);
        transform-origin: right;
    }

    100% {
        transform:scaleX(0);
        transform-origin: right;
    }
}

/** Social media **/
.login-20 .facebook-bg{
    color: #4867aa;
}

.login-20 .facebook-bg:hover {
    background: #4867aa;
}

.login-20 .twitter-bg {
    color: #33CCFF;
}

.login-20 .twitter-bg:hover {
    background: #33CCFF;
}

.login-20 .google-bg {
    color: #db4437;
}

.login-20 .google-bg:hover {
    background: #db4437;
}

.login-20 .linkedin-bg {
    color: #2392e0;
}

.login-20 .linkedin-bg:hover {
    background: #1c82ca;
}

@media (max-width: 1200px) {
    .login-20 .form-section {
        padding: 80px 50px;
    }
}

@media (max-width: 992px) {
    .login-20 .form-section {
        padding: 50px 30px;
        border-right: none;
    }

    .login-20 .bg-img{
        display: none;
    }

    .login-20 .login-box {
        max-width: 500px;
    }
}
/** Login 20 end **/

/** Login 21 start **/
.login-21{
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    /* background: url(../../assets/img/img-19.jpg) top left repeat; */
    background-size: cover;
}

.login-21 .container {
    max-width: 1120px;
    margin: 0 auto;
}

.login-21 a {
    text-decoration: none;
}


.login-21 .form-info {
    justify-content: center;
    align-items: center;
    padding: 100px 80px;
    background: #fff;
}

.login-21 .form-check-input:checked {
    display: none;
}

.login-21 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-21 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-21 .login-inner-form .form-control {
    font-size: 16px;
    outline: none;
    color: #535353;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid transparent;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 12px 45px 12px 20px;
    height: 55px;
}

.login-21 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-21 .login-inner-form .form-box i {
    position: absolute;
    top: 13px;
    right: 20px;
    font-size: 19px;
    color: #535353;
}

.login-21 .login-inner-form label{
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
}

.login-21 .login-inner-form .forgot{
    margin: 0;
    line-height: 45px;
    color: #535353;
    font-size: 15px;
    float: right;
}

.login-21 .bg-img {
    /* background: url(../../assets/img/img-21.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    position: relative;
    display: flex;
}

.login-21 .login-box{
    background: #fff;
    margin: 0 auto;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.login-21 .form-section .form-box {
    float: left;
    width: 100%;
    text-align: left;
    position: relative;
}

.login-21 .login-inner-form .btn-md {
    cursor: pointer;
    color: #fff;
    padding: 0 50px;
    height: 55px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
}

.login-21 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-21 .login-inner-form p{
    margin: 0;
    color: #535353;
}

.login-21 .login-inner-form p a{
    color: #535353;
}

.login-21 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-21 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-21 .login-inner-form .btn-theme {
    background: #15d0ad;
    border: none;
    color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.login-21 .login-inner-form .btn-theme:hover {
    background: #12bf9f;
}

.login-21 .logo{
    height: 30px;
    top: 30px;
    right: 30px;
    position: absolute;
}

.login-21 .logo img{
    height: 25px;
}

.login-21 .logo-2{
    margin-bottom: 15px;
    display: none;
}

.login-21 .logo-2 img{
    height: 30px;
}

.login-21 .nav-pills li{
    display: inline-block;
}

.login-21 .login-inner-form .checkbox {
    margin-bottom: 25px;
    font-size: 14px;
}

.login-21 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
    padding-left: 0;
}

.login-21 .login-inner-form .form-check a {
    color: #d6d6d6;
    float: right;
}

.login-21 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-21 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 2px;
    margin-left: -25px;
    border: none;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.login-21 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-21 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    color: #fff;
    background:#15d0ad;
}

.login-21 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #fff;
    line-height: 18px;
    font-size: 12px;
    content: "\2713";
    padding-left: 4px;
}

.login-21 .btn-section {
    top: 40%;
    position: absolute;
    left: 0;
    display: inline-block;
    margin-bottom: 0;
}

.login-21 .btn-section .link-btn {
    font-size: 12px;
    float: left;
    letter-spacing: 0.5px;
    text-align: left;
    font-weight: 600;
    border-radius: 0;
    width: 100%;
    color: #fff;
    text-decoration: none;
    text-decoration: blink;
    background: rgb(102 102 102 / 31%);
}

.login-21 .bs2 .link-btn{
    margin: 0 0 5px;
}

.login-21 .btn-section .default-bg{
    background: #fff;
    color: #505050;
}

.login-21 .btn-section .btn-1{
    line-height: 45px;
    text-align: center;
    width: 100px;
    text-transform: uppercase;
    border-radius: 0 50px 50px 0;
}

.login-21 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-21 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #535353;
    float: right;
    margin-left: 3px;
}

.login-21 .form-section{
    text-align: center;
}

.login-21 .form-section h3{
    margin: 0 0 40px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-21 .form-section .text {
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 0;
    color: #535353;
}

.login-21 .form-section .text a{
    color: #535353;
}

.login-21 .social-list{
    bottom: 20px;
    position: absolute;
    right: 0;
    padding: 0;
    margin: 0;
    list-style: none;
}

.login-21 .social-list a {
    width: 60px;
    height: 50px;
    line-height: 50px;
    margin-bottom: 5px;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    color: #fff;
    border-radius: 50px 0 0 50px;
    background: rgb(102 102 102 / 31%);
}

.login-21 .facebook-bg:hover {
    background: #3b589e;
}

.login-21 .twitter-bg:hover {
    background: #33CCFF;
}

.login-21 .google-bg:hover {
    background: #db4437;
}

.login-21 .linkedin-bg:hover {
    background: #1c82ca;
}

.login-21 .pinterest-bg:hover {
    background: #a30618;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-21 .bg-img{
        display: none;
    }

    .login-21 .logo-2{
        display: block;
    }

    .login-21 .form-info {
        padding: 50px 30px;
    }

    .login-21 .login-box{
        max-width: 600px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .login-21 .form-info{
        padding: 60px 30px;
    }
}
/** Login 21 end **/

/** Login 22 start **/
.login-22{
    background: #eef1f6;
}

.login-22 a {
    text-decoration: none;
}


.login-22 .bg-img{
    /* background: url(../../assets/img/img-22.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    text-align: left;
    z-index: 10;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 30px;
}

.login-22 .bg-img:after {
    position: absolute;
    left: -1px;
    top: 0;
    width: 276px;
    height: 100%;
    content: "";
    z-index: -1;
    /* background: url(../../assets/img/img-50.png) top left repeat; */
}

.login-22 .form-check-input:checked {
    display: none;
}

.login-22 .form-section{
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-22 .form-section .form-box {
    width: 100%;
    position: relative;
}

.login-22 .login-inner-form {
    max-width: 450px;
    color: #535353;
    width: 100%;
    text-align: center;
}

.login-22 .login-inner-form p{
    color: #535353;
    font-size: 16px;
    margin: 0;
}

.login-22 .login-inner-form p a{
    color: #535353;
    font-weight: 500;
}

.login-22 .login-inner-form img {
    margin-bottom: 15px;
    height: 30px;
}

.login-22 .login-inner-form h3 {
    margin: 0 0 30px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-22 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-22 .login-inner-form .form-control {
    outline: none;
    width: 100%;
    padding: 10px 25px;
    font-size: 16px;
    outline: 0;
    font-weight: 400;
    color: #535353;
    height: 55px;
    border-radius: 50px;
    border: 1px solid #fff;
}

.login-22 .login-inner-form .btn-md {
    cursor: pointer;
    height: 55px;
    color: #fff;
    padding: 15.5px 50px 14.5px 50px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 50px;
}

.login-22 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-22 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-22 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-22 .login-inner-form .btn-theme {
    background: #ffa637;
    border: none;
    color: #fff;
}

.login-22 .login-inner-form .btn-theme:hover {
    background: #ed972c;
}

.login-22 .login-inner-form .checkbox .terms {
    margin-left: 3px;
}

.login-22 .informeson {
    color: #fff;
    max-width: 500px;
    margin: 0 20px 0 70px;
}

.login-22 .informeson h1{
    color: #fff;
    margin-bottom: 20px;
    font-size: 45px;
    font-weight: 600;
    text-transform: uppercase;
}

.login-22 .informeson h1 span{
    color: #ffa637;
}

.login-22 .informeson p{
    color: #fff;
    margin-bottom: 20px;
    line-height: 28px;
}

.login-22 .social-list a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: inline-block;
    font-size: 20px;
    margin: 2px;
    border-radius: 5%;
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.login-22 .social-list a:hover {
    color: #fff;
}

.login-22 .none-2{
    display: none;
}

.login-22 .btn-section {
    margin-bottom: 30px;
}

.login-22 .informeson .link-btn:hover {
    text-decoration: none;
}

.login-22 .informeson .link-btn {
    background: #fff;
    padding: 9px 30px;
    font-size: 15px;
    border-radius: 3px;
    margin-right: 2px;
    letter-spacing: 0.5px;
    font-weight: 400;
    color: #ffa637;
    font-family: 'Jost', sans-serif;
}

.login-22 .informeson .active {
    background: #ffa637;
    color: #fff;
}

.login-22 .login-inner-form .terms{
    margin-left: 3px;
}

.login-22 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
    padding-left: 0;
}

.login-22 .login-inner-form .form-check a {
    color: #535353;
    float: right;
}

.login-22 .login-inner-form .form-check-input {
    display: none;
}

.login-22 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 20px;
    top: 0;
    height: 20px;
    margin-left: -25px;
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: #fff;
}

.login-22 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 16px;
}

.login-22 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #ffa637;
    border-color: #ffa637;
}

.login-22 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #fff;
    line-height: 19px;
    font-size: 14px;
    content: "\2713";
    padding-left: 1px;
}

.login-22 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-22 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #535353;
    float: right;
}

/** Social media **/
.login-22 .facebook-bg {
    color: #4867aa;
}

.login-22 .facebook-bg:hover {
    background: #4867aa;
}

.login-22 .twitter-bg {
    color: #33CCFF;
}

.login-22 .twitter-bg:hover {
    background: #33CCFF;
}

.login-22 .google-bg {
    color: #db4437;
}

.login-22 .google-bg:hover {
    background: #db4437;
}

.login-22 .linkedin-bg {
    color: #2392e0;
}

.login-22 .linkedin-bg:hover {
    background: #1c82ca;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-22 .bg-img {
        display: none;
    }

    .login-22 .form-section{
        padding: 30px 15px;
    }
}
/** Login 22 end **/

/** Login 23 start **/
.login-23 .bg-color-23 {
    /* background: url(../../assets/img/img-23.png) top left repeat; */
    background-size: cover;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    z-index: 999;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
}


.login-23 .form-section{
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    background: #fff;
    padding: 50px;
    border-radius: 30px;
}

.login-23 .form-box{
    width: 100%;
}

.login-23 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-23 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-23 .login-inner-form .form-control {
    font-size: 15px;
    outline: none;
    color: #535353;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.login-23 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-23 .login-inner-form .form-box .form-control {
    float: left;
    width: 100%;
    padding: 13px 15px 13px 65px;
}

.login-23 .login-inner-form .form-box i {
    position: absolute;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #fab55d;
    border-radius: 3px 0 0 3px;
    font-size: 20px;
    color: #fff;
}

.login-23 .info {
    color: #fff;
    margin: 0 117px 0 auto;
    text-align: right;
    max-width: 700px;
}

.login-23 .bg-img {
    top: 0;
    bottom: 0;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-23 .waviy {
    position: relative;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
}

.login-23 .waviy span {
    position: relative;
    display: inline-block;
    color: #040404;
    text-transform: uppercase;
    animation: waviy 1s infinite;
    animation-delay: calc(.1s * var(--i));
    font-family: 'Jost', sans-serif;
}

.login-23 .waviy span.color-yellow{
    color: #fab55d;
}

@keyframes waviy {
    0%,40%,100% {
        transform: translateY(0)
    }
    20% {
        transform: translateY(-20px)
    }
}

.login-23 .login-inner-form .form-label{
    margin-bottom: 5px;
    color: #535353;
    float: left!important;
}

.login-23 .info P{
    color: #535353;
    font-size: 17px;
    line-height: 30px;
    margin-bottom: 0;
}

.login-23 .login-inner-form .forgot{
    margin: 0;
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    float: right;
}

.login-23 .btn-theme {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    width: 100%;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
}

.login-23 .login-inner-form p{
    margin: 0;
    color: #e7e7e7;
}

.login-23 .login-inner-form p a{
    color: #e7e7e7;
}

.login-46 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-23 .logo{
    text-align: center;
    margin-bottom: 15px;
}

.login-23 .logo img{
    height: 25px;
}

.login-23 h3{
    text-align: center;
    margin: 0 0 24px;
    font-size: 22px;
    color: #fab55d;
    font-weight: 500;
}

.login-23 .form-check-input:checked {
    background-color: #fab55d!important;
    border-color: #fab55d!important;
}

.login-23 .login-inner-form .social-list{
    padding: 0;
    text-align: center;
}

.login-23 .login-inner-form .social-list li {
    display: inline-block;
}

.login-23 .login-inner-form .social-list li a {
    margin: 1px;
    font-size: 15px;
    font-weight: 400;
    width: 120px;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    color: #fff;
}

.login-23 .login-inner-form .social-list li a:hover{
    text-decoration: none;
}

.login-23 .login-inner-form .extra-login {
    float: left;
    width: 100%;
    margin: 25px 0;
    text-align: center;
    position: relative;
}

.login-23 .login-inner-form .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #23396726;
    content: "";
}

.login-23 .login-inner-form .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    background: #fff;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-size: 15px;
    color: #535353;
    text-transform: capitalize;
}

.login-23 .login-inner-form .terms{
    margin-left: 3px;
}

.login-23 .login-inner-form .checkbox {
    margin-bottom: 25px;
    font-size: 15px;
}

.login-23 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-23 .login-inner-form .form-check a {
    color: #535353;
    float: right;
}

.login-23 .login-inner-form .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-23 .btn{
    box-shadow: none!important;
}

.login-23 .btn-lg{
    padding: 0 50px;
    line-height: 50px;
}

.login-23 .btn-md{
    padding: 0 50px;
    line-height: 45px;
}

.login-23 .btn-primary{
    background: #fab55d;
}

.login-23 .btn-primary:hover {
    background: #eba750;
}

.login-23 .form-section a {
    text-decoration: none;
}

.login-23 .form-section .form-check-input {
    width: 18px;
    height: 18px;
    margin-top: 1px;
    position: absolute;
    border: 1px solid #dbdbdb;
    border-radius: 0;
    background-color: #fff;
    margin-left: -22px;
}

.login-23 .login-inner-form .checkbox a {
    font-size: 16px;
    font-weight: 400;
    color: #535353;
}

.login-23 .form-section p {
    margin: 28px 0 0;
    font-size: 16px;
    color: #535353;
}

.login-23 .form-section p a {
    color: #535353;
}

.login-23 .facebook-bg {
    background: #4867aa;
}

.login-23 .facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.login-23 .twitter-bg {
    background: #33CCFF;
}

.login-23 .twitter-bg:hover {
    background: #56d7fe;
}

.login-23 .google-bg {
    background: #db4437;
}

.login-23 .google-bg:hover {
    background: #dc4e41;
}

/** MEDIA **/
@media (max-width: 1200px) {
    .login-23 .info {
        margin: 0;
    }

    .login-23 .waviy {
        font-size: 33px;
    }
}

@media (max-width: 992px) {
    .login-23 .bg-img{
        display: none;
    }

    .login-23 .login-inner-form .social-list li a {
        width: 100px;
        height: 35px;
        line-height: 35px;
        font-size: 14px;
    }

    .login-23 .bg-color-23 {
        padding: 30px 15px;
    }

    .login-23 .form-section {
        padding: 50px 30px;
    }
}
/** Login 23 end **/

/** Login 24 start **/
.login-24 {
    background: #4734e7;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
}

.login-24 a {
    text-decoration: none;
}

.login-24 #bg {
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
}

.login-24 #bg canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.login-24 .btn-section{
    margin-bottom: 50px;
}

.login-24 .link-btn {
    padding: 11px 30px;
    text-decoration: none;
    font-size: 17px;
    border-radius: 3px;
    color: #6f6d6d;
    border: 2px solid #fff;
    background: #fff;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
}

.login-24 .form-check-input:checked {
    display: none;
}


.login-24 .link-btn:hover{
    border: 2px solid #fff;
    color: red;
    background: #fff;
}

.login-24 .logo img{
    margin-bottom: 50px;
    height: 40px;
}

.login-24 .active {
    border: 2px solid #fff;
    background: #fff;
    color: red;
}

.login-24 .form-section {
    max-width: 500px;
    color: #fff;
    margin: 0 auto;
}

.login-24 .login-inner-form .form-group {
    margin-bottom: 40px;
}

.login-24 .login-inner-form .form-control {
    width: 100%;
    padding: 10px 25px;
    font-size: 18px;
    font-weight: 400;
    outline: none;
    color: #000!important;
    border-radius: 3px;
    height: 60px;
    border: 1px solid #fff;
    background: #fff;
    font-family: 'Jost', sans-serif;
}

.login-24 .login-inner-form label{
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
    opacity: 0.9;
    font-family: 'Jost', sans-serif;
}

.login-24 .login-inner-form .forgot{
    margin: 0;
    line-height: 60px;
    opacity: 0.9;
    color: #fff;
    font-size: 16px;
    float: right;
}

.login-24 .login-inner-form .btn-md {
    cursor: pointer;
    height: 60px;
    color: #fff;
    padding: 13px 50px 12px 50px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
}

.login-24 .login-inner-form p a{
    color: #fff;
    opacity: 0.9;
}

.login-24 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-24 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-24 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-24 .login-inner-form .btn-theme {
    background: #f0151f;
    border: none;
    color: #fff;
}

.login-24 .login-inner-form .btn-theme:hover {
    background: #dc141d;
}

.login-24 .tab-box .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #000;
    border: solid 2px rgba(23, 23, 23, 0.72);
    background: none;
}

/** MEDIA **/
@media (max-width: 768px) {
    .login-24 {
        padding: 30px 0;
    }
}
/** Login 24 end **/

/** Login 25 start **/
.login-25 {
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    overflow: hidden;
    background: #fff4f4;
}

.login-25 .info{
    padding: 0 15px;
    max-width: 600px;
    margin: auto;
    text-align: left;
}

.login-25 .info p {
    line-height: 28px;
    color: #535353;
}

.login-25 .animate-charcter {
    background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 55px;
    font-weight: 700;
    margin-bottom: 30px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.login-25 .form-section {
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
}


.login-25 a {
    text-decoration: none;
}

.login-25 .form-inner {
    max-width: 550px;
    width: 100%;
    padding: 60px;
    text-align: center;
    z-index: 999;
    background: #fff;
}

.login-25 .form-text {
    display: flex;
}

.login-25 .form-section .extra-login {
    float: left;
    width: 100%;
    margin: 25px 0 25px;
    text-align: center;
    position: relative;
}

.login-25 .form-section .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #d8dcdc;
    content: "";
}

.login-25 .form-section .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-size: 14px;
    color: #535353;
    text-transform: capitalize;
    background: #fff;
}

.login-25 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    vertical-align: top;
    position: absolute;
    border-radius: 2px;
    border: none;
    background-color: #f7f7f7;
    margin-left: -22px;
}

.login-25 .form-check-input:focus {
    border-color: snow;
    outline: 0;
    box-shadow: none;
}

.login-25 .form-check-input:checked {
    background-color: #ff8a00;
}

.login-25 .form-section p {
    color: #535353;
    margin-bottom: 0;
    font-size: 16px;
}

.login-25 .form-section p a {
    color: #535353;
}

.login-25 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-25 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-25 .form-section .thembo {
    margin-left: 4px;
}

.login-25 .form-section h3 {
    margin: 0 0 25px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-25 .form-section .form-group {
    margin-bottom: 25px;
}

.login-25 .form-section .form-group {
    width: 100%;
    position: relative;
}

.login-25 .form-section .form-control {
    padding: 11px 20px 9px;
    font-size: 16px;
    outline: none;
    height: 50px;
    color: #535353;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid transparent;
    background: #f7f7f7;
}

.login-25 .form-section .checkbox {
    font-size: 15px;
}

.login-25 .form-section .form-check {
    margin-bottom: 0;
}

.login-25 .form-section .form-check a {
    color: #535353;
}

.login-25 .form-section .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: #535353;
    text-align: left;
}

.login-25 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
}

.login-25 .logo img {
    margin-bottom: 15px;
    height: 25px;
}

.login-25 .btn-theme {
    position: relative;
    display: inline-block;
    border: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    width: 100%;
}

.login-25 .btn-theme:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    border-radius: 3px;
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

.login-25 .btn-theme:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.login-25 .btn-lg{
    padding: 0 50px;
    line-height: 50px;
}

.login-25 .btn{
    box-shadow: none!important;
}

.login-25 .btn-md{
    padding: 0 50px;
    line-height: 45px;
}

.login-25 .btn-primary{
    background-image: linear-gradient(to bottom, #ff4a4a, #ff8a00);
}

.login-25 .btn-primary:after {
    background-image: linear-gradient(to bottom, #ff8a00, #ff4a4a);
}

.login-25 .social-list {
    display: inline-flex;
    margin-bottom: 27px;
}

.login-25 .social-list .icon {
    position: relative;
    background: #f3f3f3;
    border-radius: 3px;
    margin: 0 4px 3px 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #ffffff;
}

.login-25 .social-list .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-25 .social-list .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-25 .social-list .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.login-25 .social-list .icon:hover span,
.login-25 .social-list .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.login-25 .social-list .facebook{
    background: #3b5999;
}

.login-25 .social-list .facebook:hover .tooltip,
.login-25 .social-list .facebook:hover .tooltip::before {
    background: #3b5999;
}

.login-25 .social-list .twitter{
    background: #46c1f6;
}

.login-25 .social-list .twitter:hover .tooltip,
.login-25 .social-list .twitter:hover .tooltip::before {
    background: #46c1f6;
}

.login-25 .social-list .instagram{
    background: #db4437;
}

.login-25 .social-list .instagram:hover .tooltip,
.login-25 .social-list .instagram:hover .tooltip::before {
    background: #db4437;
}

.login-25 .social-list .github{
    background: #2392e0;
}

.login-25 .social-list .github:hover .tooltip,
.login-25 .social-list .github:hover .tooltip::before {
    background: #2392e0;
}

/** Social media **/
@media (max-width: 992px) {
    .login-25 .form-text {
        display: none;
    }
}

@media (max-width: 768px) {
    .login-25 .form-inner {
        padding: 50px 30px;
    }
}
/** Login 25 end **/

/** Login 26 start **/
.login-26 {
    /* background: url(../../assets/img/img-7.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    width: 100%;
    text-align: center;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.login-26 .form-section {
    max-width: 550px;
    margin: 0 auto;
    padding: 70px 50px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    z-index: 999;
}

.login-26 #particles-js {
    background-size: cover;
    background-position: 50% 50%;
    position: fixed;
    min-height: 100vh;
    width: 100%;
    z-index: -999;
}

.login-26 .form-section p{
    color: #535353;
    margin-bottom: 0;
    text-align: center;
    font-size: 16px;
}


.login-26 .form-section a {
    text-decoration: none;
}

.login-26 .form-section p a{
    color: #535353;
    font-weight: 500;
}

.login-26 .form-section .extra-login {
    float: left;
    width: 100%;
    margin: 25px 0 25px;
    text-align: center;
    position: relative;
}

.login-26 .form-section .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #d8dcdc;
    content: "";
}

.login-26 .form-section .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    background: #fff;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-size: 15px;
    color: #535353;
    text-transform: capitalize;
}

.login-26 .form-section ul{
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-26 .logo-2 img{
    margin-bottom: 20px;
    height: 30px;
}

.login-26 .form-section .social-list li {
    display: inline-block!important;
    margin-bottom: 5px;
}

.login-26 .form-section .social-list li a {
    font-size: 14px;
    font-weight: 400;
    width: 130px;
    margin: 2px 0 3px 0;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-family: 'Jost', sans-serif;
}

.login-26 .form-section .social-list li a i{
    height: 40px;
    width: 40px;
    line-height: 40px;
    float: left;
    color: #fff;
    border-radius: 20px;
}

.login-26 .form-section .social-list li a span{
    margin-right: 7px;
}

.login-26 .form-section .thembo{
    margin-left: 4px;
}

.login-26 .form-section h3 {
    margin: 0 0 30px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-26 .main-title .words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0;
}

.login-26 .main-title .words-wrapper b.visible {
    position: relative;
}

.login-26 .main-title .no-js .words-wrapper b {
    opacity: 0;
}

.login-26 .main-title .no-js .words-wrapper b.visible {
    opacity: 1;
}

.login-26 .main-title .headline{
    margin: 0 0 30px;
    font-size: 25px;
    font-weight: 400;
    color: #313131;
}

.login-26 .main-title .headline b {
    opacity: 0;
    font-weight: 400;
}

.login-26 .main-title .headline b.visible {
    top: 0;
    opacity: 1;
    -webkit-animation: slide-in 0.6s;
    -moz-animation: slide-in 0.6s;
    animation: slide-in 0.6s;
}

.login-26 .main-title .headline b.is-hidden {
    -webkit-animation: slide-out 0.6s;
    -moz-animation: slide-out 0.6s;
    animation: slide-out 0.6s;
}

.login-26 .main-title .visible{
    color: #01c5ff;
}

@-webkit-keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateY(20%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

.login-26 .form-section .form-group {
    margin-bottom: 25px;
}

.login-26 .form-section .form-control {
    float: left;
    width: 100%;
    padding: 12px 20px 12px 20px;
    height: 50px;
}

.login-26 .form-section .form-control {
    font-size: 16px;
    outline: none;
    background: #e8e8e8;
    color: #535353;
    border-radius: 3px;
    border: 1px solid #e8e8e8;
}

.login-26 .form-section .checkbox .terms{
    margin-left: 3px;
}

.login-26 .form-section button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-26 .form-section .terms{
    margin-left: 3px;
}

.login-26 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    position: absolute;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    background-color: #e8e8e8;
    margin-left: -22px;
}

.login-26 .form-section .checkbox {
    margin-bottom: 20px;
}

.login-26 .form-section .form-check{
    margin-bottom: 0;
    text-align: left;
}

.login-26 .form-section .form-check a {
    color: #535353;
}

.login-26 .form-section .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-26 .form-check-input:checked {
    background-color: #01c5ff;
    border-color: #01c5ff;
}

.login-26 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
}

.login-26 .btn-theme {
    color: #fff;
    text-align: center;
    border: 2px solid transparent;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: all .7s ease;
    border-radius: 3px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    width: 100%;
}

.login-26 .btn-theme:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
    border-radius: 3px;
}

.login-26 .btn-theme:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
}

.login-26 .btn-theme:hover {
    background: transparent;
}

.login-26 .btn-theme:hover:before {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-26 .btn-theme:hover:after {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-26 .btn-lg{
    padding: 0 50px;
    line-height: 46px;
}

.login-26 .btn{
    box-shadow: none!important;
}

.login-26 .btn-md{
    padding: 0 45px;
    line-height: 41px;
}

.login-26 .btn-primary {
    background: #01c5ff;
}

.login-26 .btn-primary:before {
    background: #01c5ff;
}

.login-26 .btn-primary:after {
    background: #01c5ff;
}

.login-26 .btn-primary:hover {
    color: #01c5ff;
    border: 2px solid #01c5ff;
}

/** Social media **/
.login-26 .facebook-i {
    background: #4867aa;
    color: #fff;
}

.login-26 .twitter-i {
    background: #33CCFF;
    color: #fff;
}

.login-26 .google-i {
    background: #db4437;
    color: #fff;
}

.login-26 .facebook-color{
    color: #4867aa;
}

.login-26 .twitter-color {
    color: #33CCFF;
}

.login-26 .google-color {
    color: #db4437;
}

@media (max-width: 768px) {
    .login-26 .form-section {
        padding: 50px 30px;
    }
}

@media (max-width: 500px) {
    .login-26 .form-section .social-list li a i {
        display: none;
    }

    .login-26 .form-section .social-list li a {
        width: 100px;
    }
}
/** Login 26 end **/

/** Login 27 start **/
.login-27 {
    top: 0;
    width: 100%;
    text-align: center;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.login-27 a {
    text-decoration: none;
}


.login-27 .form-section {
    max-width: 500px;
    margin: 0 auto;
}

.login-27 .form-section .details{
    padding: 60px;
    border-radius: 5px;
    margin-bottom: 20px;
    background: #f5f5f5;
}

.login-27 .form-check-input:checked {
    display: none;
}

.login-27 .form-section p{
    color: #535353;
    margin-bottom: 0;
    font-size: 16px;
}

.login-27 .form-section p a{
    color: #535353;
}

.login-27 .form-section ul{
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-27 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-27 .logo-2 img{
    margin-bottom: 20px;
    height: 30px;
}

.login-27 .form-section .thembo{
    margin-left: 4px;
}

.login-27 .form-section h3 {
    margin: 0 0 40px;
    font-size: 25px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    color: #040404;
}

.login-27 .form-section .form-group {
    margin-bottom: 30px;
}

.login-27 .form-section .form-box {
    float: left;
    width: 100%;
    position: relative;
    text-align: left;
}

.login-27 .form-section .form-control {
    font-size: 16px;
    outline: none;
    background: transparent!important;
    color: #535353;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;
    border-bottom: solid 2px #bdbdbd;
}

.login-27 .form-section .btn-md {
    cursor: pointer;
    padding: 12px 50px 11px 50px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    height: 45px;
}

.login-27 .form-section button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-27 .form-section .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-27 .form-section .terms{
    margin-left: 3px;
}

.login-27 .form-section input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-27 .form-section .checkbox a {
    float: right;
}

.login-27 .form-section .checkbox {
    margin-bottom: 25px;
    font-size: 15px;
}

.login-27 .form-section .form-check{
    float: left;
    margin-bottom: 0;
    padding-left: 0;
}

.login-27 .form-section .form-check a {
    color: #535353;
    float: right;
}

.login-27 .form-section .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-27 .form-section .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 2px;
    margin-left: -25px;
    border-radius: 0px;
    background: #f5f5f5;
    border: solid 2px #bdbdbd;
}

.login-27 .form-section .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-27 .form-section input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #fff;
    line-height: 15px;
    font-size: 12px;
    content: "\2713";
    padding-left: 3px;
}

.login-27 .form-section .checkbox-theme input[type="checkbox"]:checked + label::before {
    color: #fff;
    background: #8686ff;
    border: solid 2px #8686ff;
}

.login-27 .form-section .btn-theme {
    background: #8686ff;
    border: none;
    color: #fff;
}

.login-27 .form-section .btn-theme:hover {
    background: #7878f3;
}

.login-27 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
}

.login-27 .social-list{
    float: left;
}

.login-27 .social-list span {
    margin-right: 5px;
    font-weight: 500;
    font-size: 15px;
    color: #535353;
}

.login-27 .social-list a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    color: rgb(255, 255, 255);
    margin: 0 2px 2px 0;
    border-radius: 5%;
}

.login-27-bodycolor .ripple-background{
    z-index: -999!important;
}

.login-27-bodycolor .ripple-background .circle{
    position: absolute;
    border-radius: 50%;
    animation: ripple 15s infinite;
}

.login-27-bodycolor .ripple-background .small{
    width: 200px;
    height: 200px;
    left: -100px;
    top: -100px;
    z-index: -999;
}

.login-27-bodycolor .ripple-background .medium{
    width: 400px;
    height: 400px;
    left: -200px;
    bottom: -200px;
    z-index: -999;
}

.login-27-bodycolor .ripple-background .large{
    width: 600px;
    height: 600px;
    left: -300px;
    top: -300px;
    z-index: -999;
}

.login-27-bodycolor .ripple-background .xlarge{
    width: 800px;
    height: 800px;
    left: -400px;
    top: -400px;
    z-index: -999;
}

.login-27-bodycolor .ripple-background .xxlarge{
    width: 1000px;
    height: 1000px;
    left: -500px;
    top: -500px;
    z-index: -999;
}

.login-27-bodycolor .ripple-background .shade1{
    background: #0000ff26;
}

.login-27-bodycolor .ripple-background .shade2{
    background: #b9b9ff;
}

.login-27-bodycolor .ripple-background .shade3{
    background: #0000ff26;
}

.login-27-bodycolor .ripple-background .shade4{
    background: #0a58ca;
}

.login-27-bodycolor .ripple-background .shade5{
    background: #0000ff26;
}

@keyframes ripple{
    0%{
        transform: scale(0.8);
    }

    50%{
        transform: scale(1.2);
    }

    100%{
        transform: scale(0.8);
    }
}

/** Social media **/
.login-27 .facebook-bg{
    background: #4867aa;
}

.login-27 .facebook-bg:hover {
    background: #3b589e;
}

.login-27 .twitter-bg {
    background: #33CCFF;
}

.login-27 .twitter-bg:hover {
    background: #2abdef;
}

.login-27 .google-bg {
    background: #db4437;
}

.login-27 .google-bg:hover {
    background: #cc4437;
}

.login-27 .linkedin-bg {
    background: #2392e0;
}

.login-27 .linkedin-bg:hover {
    background: #1c82ca;
    color: #fff;
}

@media (max-width: 768px) {
    .login-27 .form-section .details{
        padding: 50px 30px;
    }

    .login-27-bodycolor .ripple-background{
        display: none;
    }
}
/** Login 27 end **/

/** Login 28 start **/
.login-28 {
    padding: 25px 0;
    z-index: 999;
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.login-28 .logo {
    border-radius: 50px;
    margin-bottom: 0;
    display: inline-block;
    top: 20px;
    position: absolute;
    right: 20px;
}

.login-28 .login-box {
    background: #fff;
    margin: 0 auto;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.login-28 .login-box .form-info {
    background: #fff;
}

.login-28 .form-section {
    padding: 130px 70px 60px;
    border-radius: 10px 0 0 10px;
    position: relative;
}

.login-28-bg {
    background: #f7f7f7;
}

.login-28 .pad-0 {
    padding: 0;
}

.login-28 label {
    color: #535353;
    font-size: 16px;
    font-weight: 500;
}

.login-28 .form-section p {
    color: #535353;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 40px;
}

.login-28 .form-section p a {
    color: #535353;
    font-weight: 500;
}

.login-28 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-28 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-28 .form-section .thembo {
    margin-left: 4px;
}

.login-28 .form-section h1 {
    font-size: 30px;
    font-weight: 500;
    color: #040404;
}

.login-28 .form-section h3 {
    margin: 0 0 35px;
    font-size: 18px;
    font-weight: 500;
    color: #313131;
    text-transform: uppercase;
}

.login-28 .form-section .form-group {
    margin-bottom: 25px;
}

.login-28 .form-section .form-box .form-control {
    float: left;
    width: 100%;
}

.login-28 .form-section .form-control {
    padding: 10px 20px;
    font-size: 15px;
    outline: none;
    height: 50px;
    color: #fff;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid #1a1a1a26;
    background: #fff;
}

.login-28 .form-section .checkbox .terms {
    margin-left: 3px;
}

.login-28 .form-section button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-28 .form-section a {
    text-decoration: none;
}

.login-28 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    position: absolute;
    border-radius: 3px;
    background-color: #fff;
    margin-left: -22px;
}

.login-28 .login-inner-form .form-check {
    float: left;
    margin-bottom: 0;
}

.login-28 .form-section .form-check .form-check-input {
    margin-left: -22px;
}

.login-28 .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}

.login-28 .form-check-input:checked {
    background-color: #17dada;
    border-color: #17dada!important;
}

.login-28 .none-2 {
    display: none;
}

.login-28 .form-section .terms {
    margin-left: 3px;
}

.login-28 .btn-section {
    border-radius: 50px;
    margin-bottom: 0;
    display: inline-block;
    position: absolute;
    left: 70px;
    top: 70px;
}

.login-28 .btn-section .link-btn {
    font-size: 14px;
    float: left;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    text-decoration: blink;
    width: 100px;
    padding: 6px 5px;
    margin-right: 5px;
    color: #fff;
    border-radius: 3px;
    background: #1fefef;
    background-image: radial-gradient( circle, rgba( 0, 0, 0, 0 ) 0%, rgb(14 193 193) 100% );
}

.login-28 .btn-section .active-bg {
    color: #fff;
}

.login-28 .form-section .checkbox {
    font-size: 14px;
}

.login-28 .form-section .form-check {
    float: left;
    margin-bottom: 0;
}

.login-28 .form-section .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-28 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
}

.login-28 .social-list a {
    text-align: center;
    display: inline-block;
    font-size: 18px;
    margin: 0 10px 0 0;
    color: #535353;
}

.login-28 .social-list a:hover {
    color: #17dada;
}

.login-28 .btn-theme {
    color: #fff;
    text-align: center;
    border: 2px solid transparent;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: all .7s ease;
    border-radius: 3px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    width: 100%;
}

.login-28 .btn-theme:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
    border-radius: 3px;
}

.login-28 .btn-theme:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
}

.login-28 .btn-theme:hover {
    background: transparent;
}

.login-28 .btn-theme:hover:before {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-28 .btn-theme:hover:after {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-28 .btn-lg{
    padding: 0 50px;
    line-height: 46px;
}

.login-28 .btn{
    box-shadow: none!important;
}

.login-28 .btn-md{
    padding: 0 45px;
    line-height: 41px;
}

.login-28 .btn-primary {
    background: #1fefef;
    background-image: radial-gradient( circle, rgba( 0, 0, 0, 0 ) 0%, rgb(14 193 193) 100% );
}

.login-28 .btn-primary:before {
    background: #1fefef;
}

.login-28 .btn-primary:after {
    background: #1fefef;
}

.login-28 .btn-primary:hover {
    color: #1fefef;
    border: 2px solid #1fefef;
}

.login-28 .login-box{
    background: #37ffff;
    background-image: radial-gradient( circle, rgba( 0, 0, 0, 0 ) 0%, rgba( 0, 0, 0, 0.8 ) 100% );
}

.login-28 #cup-info {
    max-width: 330px;
    margin: 0 auto;
}

.login-28 .steam {
    position: absolute;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: #fff;
    margin-top: -75px;
    margin-left: 75px;
    z-index: 0;
    opacity: 0;
}

.login-28 #steam1 {
    -webkit-animation: steam1 4s ease-out infinite;
    animation: steam1 4s ease-out infinite;
}

.login-28 #steam3 {
    -webkit-animation: steam1 4s ease-out 1s infinite;
    animation: steam1 4s ease-out 1s infinite;
}

@-webkit-keyframes steam1 {
    0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(-20px) scale(1); opacity: 0;}
}

@keyframes steam1 {
    0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(-20px) scale(1); opacity: 0;}
}

.login-28 #steam2 {
    -webkit-animation: steam2 4s ease-out 0.5s infinite;
    animation: steam2 4s ease-out 0.5s infinite;
}

.login-28 #steam4 {
    -webkit-animation: steam2 4s ease-out 1.5s infinite;
    animation: steam2 4s ease-out 1.5s infinite;
}

@-webkit-keyframes steam2 {
    0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(20px) scale(1); opacity: 0;}
}

@keyframes steam2 {
    0% {transform: translateY(0) translateX(0) scale(0.25); opacity: 0.2;}
    100% {transform: translateY(-200px) translateX(20px) scale(1); opacity: 0;}
}

.login-28 #cup {
    z-index: 1;
}

.login-28 #cup-body {
    position: absolute;
    height: 200px;
    width: 300px;
    border-radius: 0 0 150px 150px;
    background-color: #fff;
    margin: auto;
    display: inline-block;
    overflow: hidden;
    z-index: 1;
}

.login-28 #cup-shade {
    position: relative;
    height: 300px;
    width: 200px;
    background-color: #F3F3F3;
    display: inline-block;
    margin-left: 42%;
    margin-top: -3px;
    transform: rotate(50deg);
    z-index: 1;
}

.login-28 #cup-handle {
    position: relative;
    height: 75px;
    width: 80px;
    border-radius: 0 150px 150px 0;
    border: 15px solid #F3F3F3;
    margin-bottom: 120px;
    margin-left: 250px;
    display: inline-block;
    z-index: 0;
}

.login-28 #saucer {
    position: absolute;
    height: 30px;
    width: 300px;
    border-radius: 0 0 100px 100px;
    background-color: #F9F9F9;
    margin-top: -32px;
    margin-left: 5px;
    z-index: 2;
}

.login-28 #shadow {
    height: 10px;
    width: 300px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: 6px;
    background-color: #648b9d;
}

@media (max-width: 992px) {
    .login-28 .login-box {
        max-width: 500px;
    }

    .login-28 .bg-img{
        display: none;
    }
}

@media (max-width: 768px) {
    .login-28 .btn-section {
        left: 30px;
        top: 50px;
    }

    .login-28 .form-section {
        padding: 100px 30px 50px;
    }

    .login-28 .form-section p {
        margin-bottom: 30px;
    }
}
/** Login 28 end **/

/** Login 29 start **/
.login-29 {
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    overflow: hidden;
    background: #f7f7f7;
}

.login-29 .info{
    padding: 0px 15px;
    max-width: 550px;
    margin-right: auto;
    text-align: left;
}

.login-29  .animated-text h1{
    display: block;
    text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
    /* background: url(../../assets/img/animated-text-3.png) repeat-y; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: aitf 80s linear infinite;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    margin-bottom: 30px;
    font-size: 50px;
    color: #040404;
}

@-webkit-keyframes aitf {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.login-29 .info p {
    line-height: 28px;
    color: #535353;
    margin-bottom: 30px;
}

.login-29 .form-section {
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
}


.login-29 a {
    text-decoration: none;
}

.login-29 .form-inner {
    max-width: 550px;
    width: 100%;
    padding: 60px;
    text-align: center;
    z-index: 999;
    background: #fff;
    margin-left: auto;
}

.login-29 .bg-img {
    display: flex;
}

.login-29 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    vertical-align: top;
    position: absolute;
    border-radius: 2px;
    border: none;
    background-color: #f7f7f7;
    margin-left: -22px;
}

.login-29 .form-check-input:focus {
    border-color: snow;
    outline: 0;
    box-shadow: none;
}

.login-29 .form-check-input:checked {
    background-color: #0cf5ff;
}

.login-29 .form-section p {
    color: #535353;
    margin-bottom: 0;
    font-size: 16px;
}

.login-29 .form-section p a {
    color: #535353;
}

.login-29 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-29 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-29 .form-section .thembo {
    margin-left: 4px;
}

.login-29 .form-section h3 {
    margin: 0 0 25px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-29 .form-section .form-group {
    margin-bottom: 25px;
}

.login-29 .form-section .form-group {
    width: 100%;
    position: relative;
}

.login-29 .form-section .form-control {
    padding: 0 20px;
    height: 55px;
    font-size: 16px;
    outline: none;
    color: #535353;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid transparent;
    background: #f7f7f7;
}

.login-29 .form-section .checkbox {
    font-size: 15px;
}

.login-29 .form-section .form-check {
    margin-bottom: 0;
}

.login-29 .form-section .form-check a {
    color: #535353;
}

.login-29 .form-section .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
    text-align: left;
}

.login-29 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
}

.login-29 .logo img {
    margin-bottom: 15px;
    height: 25px;
}

.login-29 .btn-theme {
    position: relative;
    display: inline-block;
    border: none;
    outline: none !important;
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    width: 100%;
}

.login-29 .btn-theme:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    border-radius: 3px;
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

.login-29 .btn-theme:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.login-29 .btn-lg{
    padding: 0 50px;
    line-height: 50px;
}

.login-29 .btn{
    box-shadow: none!important;
}

.login-29 .btn-md{
    padding: 0 50px;
    line-height: 45px;
}

.login-29 .btn-primary {
    background-image: linear-gradient(to bottom, #0cf5ff, #50d1d7);
}

.login-29 .btn-primary:after {
    background-image: linear-gradient(to bottom, #50d1d7, #0cf5ff);
}

.login-29 .social-list {
    display: inline-flex;
    margin-bottom: 27px;
}

.login-29 .social-list .icon {
    position: relative;
    background: #f3f3f3;
    border-radius: 50px;
    margin: 0 4px 3px 0;
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #ffffff;
}

.login-29 .social-list .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-29 .social-list .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-29 .social-list .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.login-29 .social-list .icon:hover span,
.login-29 .social-list .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.login-29 .social-list .facebook{
    background: #3b5999;
}

.login-29 .social-list .facebook:hover .tooltip,
.login-29 .social-list .facebook:hover .tooltip::before {
    background: #3b5999;
}

.login-29 .social-list .twitter{
    background: #46c1f6;
}

.login-29 .social-list .twitter:hover .tooltip,
.login-29 .social-list .twitter:hover .tooltip::before {
    background: #46c1f6;
}

.login-29 .social-list .instagram{
    background: #db4437;
}

.login-29 .social-list .instagram:hover .tooltip,
.login-29 .social-list .instagram:hover .tooltip::before {
    background: #db4437;
}

.login-29 .social-list .github{
    background: #2392e0;
}

.login-29 .social-list .github:hover .tooltip,
.login-29 .social-list .github:hover .tooltip::before {
    background: #2392e0;
}

/** Social media **/
@media (max-width: 992px) {
    .login-29 .bg-img {
        display: none;
    }

    .login-29 {
        padding: 30px 0;
    }

    .login-29 .form-inner{
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .login-29 .form-inner {
        padding: 50px 30px;
    }
}
/** Login 29 end **/

/** Login 30 start **/
.login-30 .login-inner-form .col-pad-0{
    padding: 0;
}

.login-30 .bg-img{
    /* background: url(../../assets/img/img-8.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    text-align: right;
    z-index: 999;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 50px 30px 40px;
}


.login-30 .form-section{
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-30 .login-inner-form {
    max-width: 500px;
    width: 100%;
}

.login-30 .logo{
    top: 40px;
    position: absolute;
    right: 45px;
}

.login-30 .logo img{
    height: 30px;
}

.login-30 .login-inner-form p{
    color: #535353;
    margin-bottom: 0;
    font-size: 16px;
}

.login-30 .login-inner-form p a{
    color: #535353;
    font-weight: 500;
    margin-left: 4px;
}

.login-30 .login-inner-form img {
    margin-bottom: 15px;
    height: 30px;
}

.login-30 .login-inner-form h1{
    font-size: 30px;
    color: #ffc801;
}

.login-30 .login-inner-form h3 {
    margin: 0 0 35px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-30 label {
    color: #535353;
    font-size: 16px;
    margin-bottom: 5px;
}

.login-30 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-30 .login-inner-form .form-control{
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #d6d6d6;
    background: #fff;
    outline: none;
    color: #535353;
    border-radius: 0;
    height: 55px;
}

.login-30 .login-inner-form .input-text label {
    color: #535353;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.login-30 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
}

.login-30 .login-inner-form .form-check a {
    color: #535353;
    float: right;
}

.login-30 .login-inner-form .checkbox {
    margin-bottom: 25px;
    font-size: 16px;
}

.login-30 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: -24px;
}

.login-30 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #535353;
    float: right;
}

.login-30 a {
    text-decoration: none;
}

.login-30 .form-section .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: #535353;
}

.login-30 .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    border: 1px solid #afabab;
}

.login-30 .login-inner-form .terms{
    margin-left: 4px;
}

.login-30 .form-check-input:focus {
    border-color: #ffc801!important;
    outline: 0;
    box-shadow: none;
}

.login-30 .form-check-input:checked {
    background-color: #ffc801!important;
}

.login-30 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-30 .btn-theme {
    position: relative;
    display: inline-block;
    width: 100%;
    color: #ffffff;
    overflow: hidden;
    overflow: hidden;
    text-transform: capitalize;
    display: inline-block;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    cursor: pointer;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 0;
    border:none;
}

.login-30 .btn-theme:hover {
    color: #fff;
}

.login-30 .btn-theme:hover::before {
    left: 0%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.login-30 .btn-theme:before {
    position: absolute;
    content: '';
    left: 96%;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    transform: skewX(-25deg);
}

.login-30 .btn-theme span {
    position: relative;
    z-index: 1;
}

.login-30 .informeson {
    color: #fff;
    max-width: 600px;
    margin: 0 0 0 auto;
    z-index: 20;
}


.login-30 .informeson h2 {
    margin: 0 0 30px 0;
    font-size: 60px;
}

.login-30 .informeson h2 span {
    transform: scale(0.9);
    display: inline-block;
}

.login-30 .informeson h2 span:last-child {
    animation: bopB 2s 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite alternate;
    color: #ffc801;
}

@keyframes bop {
    0% {
        transform: scale(0.9);
    }
    50%, 100% {
        transform: scale(1);
    }
}
@keyframes bopB {
    0% {
        transform: scale(0.9);
    }
    80%, 100% {
        transform: scale(1) rotateZ(-3deg);
    }
}


.login-30 .btn-section{
    margin-bottom: 30px;
}

.login-30 .informeson p{
    color: #fff;
    opacity: 0.9;
    line-height: 30px;
    margin-bottom: 40px;
}

.login-30 .social-box ul{
    margin: 0;
    padding: 0;
}

.login-30 .social-box .social-list li {
    font-size: 16px;
    display: inline-block;
}

.login-30 .social-box .social-list li a {
    margin: 0 0 3px 3px;
    font-size: 18px;
    width: 45px;
    height: 45px;
    border-radius: 0;
    display: inline-block;
    text-align: center;
    line-height: 45px;
}

.login-30 .social-box{
    bottom: 40px;
    position: absolute;
    right: 45px;
}

.login-30 .logo-2{
    display: none;
}

.login-30 .btn-theme-2 {
    padding: 0 35px;
    display: inline-block;
    position: relative;
    z-index: 5;
    transition: .7s ease;
    background: transparent;
    line-height: 41px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
}

.login-30 .btn-theme-2:hover{
    color: #fff;
}

.btn-theme-2:before, .btn-theme-2:after {
    color: #fff;
}

.login-30 .btn-theme-2:before, .btn-theme-2:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    transition: all .7s ease;
}

.login-30 .btn-theme-2:after, .btn-theme-2:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    transition: all .7s ease;
}

.login-30 .btn-theme-2:hover:before, .btn-theme-2:hover:after {
    width: 50%;
}

.login-30 .btn-theme-2 {
    border: 2px solid #ffc801;
    color: #ffc801;
}

.login-30 .btn-theme-2:before, .btn-theme-2:after {
    background: #ffc801;
}

.login-30 .btn-theme-3 {
    color: #fff;
    text-align: center;
    border: 2px solid transparent;
    display: inline-block;
    padding: 0 35px;
    position: relative;
    z-index: 1;
    transition: all .7s ease;
    border-radius: 0;
    line-height: 41px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
}

.login-30 .btn-theme-3:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
    border-radius: 0;
}

.login-30 .btn-theme-3:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
}

.login-30 .btn-theme-3:hover {
    background: transparent;
}

.login-30 .btn-theme-3:hover:before {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-30 .btn-theme-3:hover:after {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-30 .btn-theme-3 {
    background: #ffc801;
}

.login-30 .btn-theme-3:before {
    background: #ffc801;
}

.login-30 .btn-theme-3:after {
    background: #ffc801;
}

.login-30 .btn-theme-3:hover {
    color: #ffc801;
    border: 2px solid #ffc801;
}

.login-30 .btn-lg{
    padding: 0 50px;
    line-height: 55px;
}

.login-30 .btn{
    box-shadow: none!important;
}

.login-30 .btn-md{
    padding: 0 45px;
    line-height: 50px;
}

.login-30 .btn-primary {
    background: #ffc801;
}

.login-30 .btn-primary:before {
    background: #eab802;
}

/** Social media **/
.login-30 .facebook-bg {
    background: #4867aa;
    color: #fff;
}

.login-30 .facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.login-30 .twitter-bg {
    background: #33CCFF;
    color: #fff;
}

.login-30 .twitter-bg:hover {
    background: #56d7fe;
    color: #fff;
}

.login-30 .google-bg {
    background: #db4437;
    color: #fff;
}

.login-30 .google-bg:hover {
    background: #dc4e41;
    color: #fff;
}

.login-30 .linkedin-bg {
    background: #0177b5;
    color: #fff;
}

.login-30 .linkedin-bg:hover {
    background: #1c82ca;
    color: #fff;
}

.login-30 #bg {
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
}

.login-30 #bg canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-30 .logo-2{
        display: inherit;
    }

    .login-30 .bg-img{
        display: none;
    }

    .login-30 .form-section{
        padding: 30px 15px;
    }
}
/** Login 30 end **/

/** Login 31 start **/
.login-31{
    top: 0;
    width: 100%;
    bottom: 0;
    min-height: 100vh;
    z-index: 999;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.login-31 a {
    text-decoration: none;
}


.login-31 .container {
    max-width: 1120px;
    margin: 0 auto;
}

.login-31 .col-pad-0{
    padding: 0;
}

.login-31 .login-inner-form .details p{
    color: #fff;
    font-size: 16px;
}

.login-31 .login-inner-form .details p a{
    margin-left: 3px;
    color: #fff;
}

.login-31 .form-check-input:checked {
    display: none;
}

.login-31 .login-inner-form .details p{
    margin-bottom: 0;
}

.login-31 .login-inner-form .form-box {
    position: relative;
}

.login-31 .login-inner-form .details {
    padding:80px 0 80px 80px;
}

.login-31 .bg-img {
    border-radius: 20px;
    padding: 50px;
    margin: 80px 0;
    right: -80px;
    position: relative;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.login-31 .logo-2 {
    display: none;
}

.login-31 .inner{
    z-index: 999;
}

.login-31 .login-box-9 {
    margin: 0 80px 0 0;
    border-radius: 20px;
    opacity: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    z-index: 0;
    background-image: linear-gradient(to bottom, #61daff, #656bff);
}


.login-31 .none-2{
    display: none;
}

.login-31 .login-inner-form h3 {
    margin: 0 0 30px;
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
}

.login-31 p{
    margin-bottom: 30px;
}

.login-31 .login-inner-form .form-group {
    margin-bottom: 20px;
}

.login-31 .login-inner-form .form-control {
    outline: none;
    width: 100%;
    padding: 10px 20px;
    height: 55px;
    font-size: 15px;
    outline: 0;
    font-weight: 500;
    color: #535353;
    border-radius: 3px;
    border: solid 1px #fff;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.login-31 .login-inner-form .btn-md {
    cursor: pointer;
    height: 55px;
    color: #fff;
    padding: 13px 50px 12px 50px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    text-transform: uppercase;
}

.login-31 .bg-img .logo{
    height: 30px;
    margin-bottom: 20px;
}

.login-31 .animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.login-31 .bg-img .btn-sm{
    padding: 6px 20px 6px 20px;
    font-size: 13px;
}

.login-31 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-31 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-31 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-31 .login-inner-form .btn-theme {
    background: #302d2d;
    border: none;
    color: #fff;
}

.login-31 .login-inner-form .btn-theme:hover {
    background: #161414;
}

.login-31 .login-inner-form .terms{
    margin-left: 3px;
}

.login-31 .login-inner-form .checkbox {
    margin-bottom: 20px;
    font-size: 14px;
}

.login-31 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
    padding-left: 0;
}

.login-31 .login-inner-form .form-check a {
    color: #535353;
    float: right;
}

.login-31 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-31 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 2px;
    margin-left: -25px;
    border: 1px solid #fff;
    border-radius: 3px;
    background-color: #fff;
}

.login-31 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 16px;
    color: #fff;
}

.login-31 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #302d2d;
    border-color: #302d2d;
}

.login-31 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #fff;
    line-height: 17px;
    font-size: 12px;
    content: "\2713";
    padding-left: 1px;
}

.login-31 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-31 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #fff;
    float: right;
}

.login-31 .social-list{
    margin: 0;
    padding: 0;
}

.login-31 .social-list li {
    display: inline-block;
}

.login-31 .social-list li a {
    margin: 1px;
    font-size: 14px;
    font-weight: 500;
    width: 110px;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    color: #fff;
}

.login-31 .social-list li a:hover {
    text-decoration: none;
}

.login-31 .facebook-bg {
    background: #4867aa;
}

.login-31 .facebook-bg:hover {
    background: #3d5996;
    color: #fff;
}

.login-31 .twitter-bg {
    background: #33CCFF;
}

.login-31 .twitter-bg:hover {
    background: #56d7fe;
}

.login-31 .google-bg {
    background: #db4437;
}

.login-31 .google-bg:hover {
    background: #dc4e41;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-31 .bg-img {
        display: none;
    }

    .login-31 .logo-2 {
        display: block;
    }

    .login-31 .logo-2 img {
        height: 30px;
        margin-bottom: 20px;
    }

    .login-31 .login-box-9 {
        margin: 0 auto;
        max-width: 600px;
    }

    .login-31 .login-inner-form .details {
        padding: 60px;
    }

    .login-31 .login-inner-form h3 {
        font-size: 23px;
    }
}

@media (max-width: 768px) {
    .login-31 .login-inner-form .details {
        padding: 60px 30px;
    }
}
/** Login 31 end **/

/** Login 32 start **/
.login-32 .login-32-inner{
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    min-height: 100vh;
    text-align: center;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;
}

.login-32 a {
    text-decoration: none;
}

.login-32 .login-inner-form {
    color: #535353;
    text-align: center;
    position: relative;
}


/** Animate Image start **/
.login-32 .animate-image-1{
    position: relative;
    -webkit-animation:glide 2s ease-in-out alternate infinite;
}

.login-32 .animate-image-1 img{
    height: 200px;
    position: absolute;
    bottom: 70px;
    left: 30px;
}

@-webkit-keyframes glide  {
    from {
        right:0;
        bottom:0;
    }
    to {
        right:0;
        bottom:20px;
    }
}

.login-32 .animate-image-2{
    position: relative;
    -webkit-animation:glide 2s ease-in-out alternate infinite;
}

.login-32 .animate-image-2 img{
    height: 200px;
    position: absolute;
    bottom: 50px;
    right: 0;
}

@-webkit-keyframes glide  {
    from {
        right:0;
        bottom:0;
    }

    to {
        right:0;
        bottom:20px;
    }
}

.login-32 .form-section{
    max-width: 600px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 70px;
    background: #fff2f2;
}

.login-32 .login-inner-form .form-group {
    margin-bottom: 30px;
}

.login-32 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-32 .form-check-input:checked {
    display: none;
}

.login-32 .login-inner-form .form-control {
    font-size: 16px;
    outline: none;
    color: #535353;
    border-radius: 50px;
    border: 1px solid #fff;
    background: #fff;
    padding: 15.5px 50px 15.5px 25px;
}

.login-32 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-32 .login-inner-form .form-box i {
    position: absolute;
    top: 14px;
    right: 25px;
    font-size: 19px;
}

.login-32 .login-inner-form .forgot{
    margin: 0;
    line-height: 40px;
}

.login-32 .login-inner-form .btn-md {
    cursor: pointer;
    padding: 15.5px 50px 14.5px 50px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 50px;
}

.login-32 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-32 .login-inner-form p{
    margin: 0;
    color: #535353;
}

.login-32 .login-inner-form p a{
    color: #535353;
}

.login-32 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-32 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-32 .login-inner-form .btn-theme {
    background: #50a1ff;
    border: none;
    color: #fff;
}

.login-32 .login-inner-form .btn-theme:hover {
    background: #4595f1;
}

.login-32 .logo{
    text-align: center;
    margin-bottom: 35px;
}

.login-32 .logo img{
    height: 30px;
}

.login-32 .nav-pills li{
    display: inline-block;
}

.login-32 .login-inner-form .form-group.mb-35{
    margin-bottom: 35px;
}

.login-32 .login-inner-form .social-list li {
    display: inline-block;
}

.login-32 .login-inner-form .social-list li a {
    margin: 2px;
    font-size: 18px;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50px;
    display: inline-block;
    text-align: center;
}

.login-32 .login-inner-form .social-list li a:hover{
    text-decoration: none;
    color: #fff;
}

.login-32 .login-inner-form .extra-login {
    float: left;
    width: 100%;
    margin: 30px 0 28px;
    text-align: center;
    position: relative;
}

.login-32 .login-inner-form .extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #d8dcdc;
    content: "";
}

.login-32 .login-inner-form .extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    background: #fff2f2;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-size: 16px;
    color: #535353;
    text-transform: capitalize;
}

.login-32 .form-section p a{
    color: #535353;
}

.login-32 .btn-section{
    text-align: center;
    margin-bottom: 35px;
}

.login-32 .btn-section .link-btn{
    padding: 8px 25px;
    font-size: 16px;
    border: solid 1px #fff;
    background: #fff;
    margin-right: 5px;
    letter-spacing: 0.5px;
    border-radius: 50px;
    font-weight: 400;
    color: #535353;
    text-decoration: none;
    text-decoration: blink;
}

.login-32 .btn-section .link-btn:hover{
    color: #50a1ff;
}

.login-32 .btn-section .active {
    color: #50a1ff;
}

.login-32 .login-inner-form ul{
    margin: 0;
    padding: 0;
}

.login-32 .login-inner-form .terms{
    margin-left: 3px;
}

.login-32 .login-inner-form .checkbox {
    margin-bottom: 30px;
    font-size: 16px;
}

.login-32 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
    padding-left: 0;
}

.login-32 .login-inner-form .form-check a {
    color: #535353;
    float: right;
}

.login-32 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-32 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 22px;
    height: 22px;
    margin-left: -27px;
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: #fff;
}

.login-32 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-32 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #50a1ff;
    border-color: #50a1ff;
}

.login-32 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #fff;
    line-height: 20px;
    font-size: 12px;
    content: "\2713";
    padding-left: 0;
}

.login-32 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-32 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #535353;
    float: right;
}

.login-32 .facebook-bg {
    border: solid 2px #4867aa;
    color: #4867aa;
}

.login-32 .form-section:hover .facebook-bg{
    background: #4867aa;
    color: #fff;
}

.login-32 .twitter-bg {
    border: solid 2px #33CCFF;
    color: #33CCFF;
}

.login-32 .form-section:hover .twitter-bg {
    background: #33CCFF;
    color: #fff;
}

.login-32 .google-bg {
    border: solid 2px #db4437;
    color: #db4437;
}

.login-32 .form-section:hover .google-bg {
    background: #db4437;
    color: #fff;
}

.login-32 .form-section .linkedin-bg {
    border: solid 2px #0177b5;
    color: #0177b5;
}

.login-32 .form-section:hover .linkedin-bg {
    background: #0177b5;
    color: #fff;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-32 .form-section {
        padding: 50px 30px;
    }

    .login-32 .login-32-inner{
        padding: 15px 0;
    }

    .login-32 .animate-image-2{
        display: none;
    }

    .login-32 .animate-image-1{
        display: none;
    }
}
/** Login 32 start **/

/** Login 33 start **/
.login-33{
    background-size: cover;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    background: #fff5f5;
}

.login-33 .container{
    max-width: 1120px;
    margin: 0 auto;
}

.login-33 a {
    text-decoration: none;
}


.login-33 .login-inner-form {
    color: #cccccc;
    position: relative;
}

.login-33 .form-check-input:checked {
    display: none;
}

.login-33 .form-info {
    justify-content: center;
    align-items: center;
    padding: 100px 60px;
}

.login-33 .form-box{
    width: 100%;
    text-align: center;
}

.login-33 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-33 .login-inner-form .form-box {
    float: left;
    width: 100%;
    position: relative;
}

.login-33 .login-inner-form .form-control {
    font-size: 16px;
    outline: none;
    color: #535353;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid #d9d9d9;
    padding: 14.5px 45px 14.5px 20px;
}

.login-33 .login-inner-form img {
    margin-bottom: 5px;
    height: 40px;
}

.login-33 .login-inner-form .form-box i {
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: 20px;
    color: #535353;
}

.login-33 .login-inner-form label{
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
}

.login-33 .login-inner-form .forgot{
    margin: 0;
    line-height: 45px;
    color: #535353;
    font-size: 15px;
    float: right;
}

.login-33 .bg-img {
    /* background: url(../../assets/img/img-20.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    position: relative;
    display: flex;
    border-radius: 30px 0 0 30px;
}

.login-33 .login-box {
    background: #fff;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
}

.login-33 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    color: #fff;
    background: #5ad352;
    border: solid 1px #5ad352;
}

.login-33 .login-inner-form .btn-md {
    cursor: pointer;
    height: 55px;
    color: #fff;
    padding: 13px 50px 12px 50px;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
    text-transform: uppercase;
}

.login-33 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-33 .login-inner-form p{
    margin: 0;
    color: #535353;
}

.login-33 .login-inner-form p a{
    color: #535353;
}

.login-33 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-33 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-33 .login-inner-form .btn-theme {
    background: #5ad352;
    border: none;
    color: #fff;
}

.login-33 .login-inner-form .btn-theme:hover {
    background: #4fcb47;
}

.login-33 .logo{
    height: 30px;
    top: 30px;
    left: 30px;
    position: absolute;
}

.login-33 .logo img{
    height: 25px;
}

.login-33 .logo-2{
    margin-bottom: 15px;
    display: none;
}

.login-33 .logo-2 img{
    height: 30px;
}

.login-33 .nav-pills li{
    display: inline-block;
}

.login-33 .login-inner-form .checkbox {
    margin-bottom: 25px;
    font-size: 14px;
}

.login-33 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
    padding-left: 0;
}

.login-33 .login-inner-form .form-check a {
    color: #d6d6d6;
    float: right;
}

.login-33 .login-inner-form .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-33 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 2px;
    margin-left: -25px;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #d9d9d9;
}

.login-33 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-33 .form-section input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #e6e6e6;
    line-height: 16px;
    font-size: 14px;
    content: "\2713";
}

.login-33 .btn-section{
    float: right;
    display: inline-block;
}

.login-33 .btn-section {
    top: 30px;
    position: absolute;
    right: 30px;
    margin-bottom: 0;
}

.login-33 .btn-section .link-btn {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #5ad352;
    padding: 10px 18px;
    text-decoration: none;
    text-decoration: blink;
    text-transform: uppercase;
    border-radius: 3px;
    background: #fff;
}

.login-33 .btn-section .link-btn:hover{
    background: #5ad352;
    color: #fff;
}

.login-33 .btn-section .link-btn.active{
    background: #5ad352;
    color: #fff;
}

.login-33 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-33 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #535353;
    float: right;
    margin-left: 3px;
}

.login-33 .text {
    display: none;
}

.login-33 .form-section{
    text-align: center;
}

.login-33 .form-section h3{
    font-size: 25px;
    margin-bottom: 30px;
    font-weight: 400;
    color: #040404;
}

.login-33 .form-section .text {
    font-size: 16px;
    margin-top: 25px;
    margin-bottom: 0;
    color: #535353;
}

.login-33 .form-section .text a{
    color: #535353;
}

.login-33 .social-list{
    bottom: 28px;
    position: absolute;
    left: 30px;
    padding: 0;
    margin: 0;
    list-style: none;
}

.login-33 .social-list li{
    display: inline-block;
}

.login-33 .social-list a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin: 0 2px 2px 0;
    text-align: center;
    display: inline-block;
    font-size: 17px;
    color: #fff;
    border-radius: 50px;
}

.login-33 .facebook-bg {
    background: #3b589e;
    color: #fff;
}

.login-33 .twitter-bg {
    background: #56d7fe;
}

.login-33 .google-bg {
    background: #dc4e41;
}

.login-33 .linkedin-bg {
    background: #1c82ca;
    color: #fff;
}

.login-33 .pinterest-bg {
    background: #a30618;
    color: #fff;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-33 .bg-img{
        display: none;
    }

    .login-33 .form-section h3{
        font-size: 23px;
    }

    .login-33 .logo-2{
        display: block;
    }

    .login-33 .text {
        display: block;
    }

    .login-33 .form-info{
        padding: 50px 30px;
    }

    .login-33 .login-box{
        max-width: 600px;
        margin: 0 auto;
    }
}
/** Login 33 end **/

/** Login 34 start **/
.login-34 {
    top: 0;
    width: 100%;
    text-align: center;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    background-image: linear-gradient(to bottom, #094008, #34d731);
}

.login-34 .form-section {
    max-width: 600px;
    margin: 0 auto;
    padding: 100px;
    background: #fff;
    border-radius: 50px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    z-index: 999;
    position: relative;
}


.login-34 .form-section a {
    text-decoration: none;
}

.login-34 #particles-js {
    background-size: cover;
    background-position: 50% 50%;
    position: fixed;
    min-height: 100vh;
    width: 100%;
    z-index: -999;
}

.login-34 .form-section p{
    color: #535353;
    margin-bottom: 0;
    text-align: center;
    font-size: 16px;
}


.login-34 .form-section p a{
    color: #535353;
}

.login-34 .form-section ul{
    list-style: none;
    padding: 0;
    margin: 0 0 40px;
}

.login-34 .logo-2 img{
    margin-bottom: 20px;
    height: 30px;
}

.login-34 .form-section .social-list li {
    display: inline-block!important;
    margin-bottom: 5px;
}

.login-34 .form-section .social-list li a {
    font-size: 14px;
    font-weight: 400;
    width: 130px;
    margin: 2px 0 3px 0;
    height: 40px;
    line-height: 40px;
    border-radius: 0;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-family: 'Jost', sans-serif;
}

.login-34 .form-section .social-list li a i{
    height: 40px;
    width: 40px;
    line-height: 40px;
    float: left;
    color: #fff;
}

.login-34 .form-section .social-list li a span{
    margin-right: 7px;
}

.login-34 .form-section .thembo{
    margin-left: 4px;
}

.login-34 h1{
    margin: 0 0 30px;
    font-size: 24px;
    font-weight: 400;
    color: #040404;
}

.login-34 .form-section .form-group {
    margin-bottom: 25px;
}

.login-34 .form-section .form-control {
    font-size: 16px;
    outline: none;
    background: #fff;
    color: #535353;
    border-radius: 3px;
    border: 1px solid #fff;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    float: left;
    width: 100%;
    padding: 12px 20px 12px 20px;
    height: 55px;
}

.login-34 .form-section .checkbox .terms{
    margin-left: 3px;
}

.login-34 .form-section button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-34 .form-section .terms{
    margin-left: 3px;
}

.login-34 .form-section .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    position: absolute;
    border: 1px solid #fff;
    border-radius: 2px;
    background-color: #fff;
    margin-left: -22px;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

.login-34 .form-section .form-check{
    float: left;
    margin-bottom: 0;
}

.login-34 .form-section .form-check a {
    color: #535353;
}

.login-34 .form-section .form-check-label {
    padding-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    color: #535353;
}

.login-34 .form-check-input:checked {
    background-color: #34d731;
    border-color: #34d731;
}

.login-34 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
}

.login-34 .btn-theme {
    color: #fff;
    text-align: center;
    border: 2px solid transparent;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: all .7s ease;
    border-radius: 3px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    width: 100%;
}

.login-34 .btn-theme:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
    border-radius: 3px;
}

.login-34 .btn-theme:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .7s ease;
    z-index: -1;
}

.login-34 .btn-theme:hover {
    background: transparent;
}

.login-34 .btn-theme:hover:before {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-34 .btn-theme:hover:after {
    width: 0;
    opacity: 1;
    visibility: visible;
}

.login-34 .btn-lg{
    padding: 0 50px;
    line-height: 51px;
}

.login-34 .btn{
    box-shadow: none!important;
}

.login-34 .btn-md{
    padding: 0 45px;
    line-height: 41px;
}

.login-34 .btn-primary {
    background: #34d731;
}

.login-34 .btn-primary:before {
    background: #34d731;
}

.login-34 .btn-primary:after {
    background: #34d731;
}

.login-34 .btn-primary:hover {
    color: #34d731;
    border: 2px solid #34d731;
}

/** Social media **/
.login-34 .facebook-i {
    background: #4867aa;
    color: #fff;
}

.login-34 .twitter-i {
    background: #33CCFF;
    color: #fff;
}

.login-34 .google-i {
    background: #db4437;
    color: #fff;
}

.login-34 .facebook-color{
    color: #4867aa;
}

.login-34 .twitter-color {
    color: #33CCFF;
}

.login-34 .google-color {
    color: #db4437;
}

@media (max-width: 768px) {
    .login-34 .form-section {
        padding: 50px 30px;
    }
}

@media (max-width: 500px) {
    .login-34 .form-section .social-list li a i {
        display: none;
    }

    .login-34 .form-section .social-list li a {
        width: 100px;
    }
}
/** Login 34 end **/

/** Login 35 start **/
.login-35 {
    padding: 30px 0;
    z-index: 999;
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: #f1f1f1;
    text-align: center;
}

.login-35 a {
    text-decoration: none;
}


.login-35 .form-check-input:checked {
    display: none;
}

.login-35 .logo img {
    height: 32px;
}

.login-35 .login-box {
    max-width: 600px;
    margin: 0 auto;
}

.login-35 .form-info{
    position: relative;
    width: 100%;
}

.login-35 .form-info .titlet-top{
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
    padding: 0 50px;
}

.login-35 .form-section {
    padding: 50px;
    text-align: left;
    background: #fff;
    margin-bottom: 30px;
}

.login-35-bg {
    background: #f7f7f7;
}

.login-35 label {
    color: #535353;
    font-size: 16px;
    margin-bottom: 5px;
}

.login-35 .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.login-35 .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.login-35 .form-section .thembo {
    margin-left: 4px;
}

.login-35 .form-section h1 {
    font-size: 30px;
    font-weight: 500;
    color: #2bd1c9;
}

.login-35 .form-section h3 {
    margin: 0 0 40px;
    font-size: 23px;
    font-weight: 400;
    color: #040404;
}

.login-35 .form-section .form-group {
    margin-bottom: 25px;
}

.login-35 .form-section .form-box {
    float: left;
    width: 100%;
    text-align: left;
    position: relative;
}

.login-35 .form-section .form-control {
    padding: 10px 20px;
    font-size: 16px;
    outline: none;
    height: 50px;
    color: #535353;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid #dedede;
    background: #fff;
}

.login-35 .form-section .checkbox .terms {
    margin-left: 3px;
}

.login-35 .form-section .btn-md {
    cursor: pointer;
    padding: 10px 50px;
    height: 50px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 3px;
}

.login-35 .form-section input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-35 .form-section button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-35 .form-section .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-35 .form-section .btn-theme {
    background: #2bd1c9;
    border: none;
    color: #fff;
}

.login-35 .form-section .btn-theme:hover {
    background: #23c3bb;
}

.login-35 .none-2 {
    display: none;
}

.login-35 .form-section .terms {
    margin-left: 3px;
}

.login-35 .info {
    max-width: 500px;
    margin: 0 auto;
    align-self: center !important;
}

.login-35 .btn-section .link-btn {
    font-size: 14px;
    float: left;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    text-decoration: blink;
    width: 100px;
    padding: 6px 5px;
    margin-right: 5px;
    color: #000;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #dedede;
}

.login-35 .btn-section .active-bg {
    color: #fff;
    background: #2bd1c9;
    border: 1px solid #2bd1c9;
}

.login-35 .btn-section .link-btn:hover {
    color: #fff;
    background: #2bd1c9;
    border: 1px solid #2bd1c9;
}

.login-35 .form-section .checkbox {
    font-size: 14px;
}

.login-35 .form-section .form-check {
    float: left;
    margin-bottom: 0;
    padding-left: 0;
}

.login-35 .form-section .form-check a {
    color: #535353;
    float: right;
}

.login-35 .form-section .form-check-input {
    position: absolute;
    margin-left: 0;
}

.login-35 .form-section .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 2px;
    margin-left: -25px;
    border: 1px solid #c5c3c3;
    border-radius: 3px;
    background-color: #fff;
}

.login-35 .form-section .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: #535353;
}

.login-35 .form-section .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #2bd1c9;
    border-color: #2bd1c9;
}

.login-35 .form-section input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #fff;
    line-height: 16px;
    font-size: 12px;
    content: "\2713";
    padding-left: 3px;
}

.login-35 .form-section input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-35 .form-section a.forgot-password {
    font-size: 16px;
    color: #535353;
    float: right;
}

.login-35 .social-list {
    display: inline-flex;
}

.login-35 .social-list .icon {
    position: relative;
    background: #f3f3f3;
    border-radius: 3px;
    margin: 0 4px 3px 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #ffffff;
}

.login-35 .social-list .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-35 .social-list .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login-35 .social-list .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.login-35 .social-list .icon:hover span,
.login-35 .social-list .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.login-35 .social-list .facebook{
    background: #3b5999;
}

.login-35 .social-list .facebook:hover .tooltip,
.login-35 .social-list .facebook:hover .tooltip::before {
    background: #3b5999;
}

.login-35 .social-list .twitter{
    background: #46c1f6;
}

.login-35 .social-list .twitter:hover .tooltip,
.login-35 .social-list .twitter:hover .tooltip::before {
    background: #46c1f6;
}

.login-35 .social-list .instagram{
    background: #db4437;
}

.login-35 .social-list .instagram:hover .tooltip,
.login-35 .social-list .instagram:hover .tooltip::before {
    background: #db4437;
}

.login-35 .social-list .github{
    background: #2392e0;
}

.login-35 .social-list .github:hover .tooltip,
.login-35 .social-list .github:hover .tooltip::before {
    background: #2392e0;
}


@media (max-width: 768px) {
    .login-35 .form-section {
        padding: 40px 30px 40px;
    }

    .login-35 .form-info .titlet-top {
        margin-bottom: 20px;
        padding: 0 30px;
    }
}
/** Login 35 end **/

#loginpage .col-lg-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}